import { computed, ref, unref, watch, nextTick } from 'vue';
import type { Ref, MaybeRef } from 'vue';
import { refAutoReset, useEventListener, useFocus } from '@vueuse/core';
import { formatNumber } from '../../utils';
import {
  CHAR_EN_DASH,
  MINUS,
  NUMBER_DECIMAL_SEPARATOR,
  MINUS_SYMBOLS,
  DEFAULT_DECIMAL_SEPARATOR,
  DEFAULT_THOUSAND_SEPARATOR,
} from '../../shared/constants';
import { Keys } from '../../shared/enums';

interface UseInputNumberOptions {
  precision?: MaybeRef<number>;
  decimalSeparator?: MaybeRef<string>;
  thousandSeparator?: MaybeRef<string>;
  decimal?: MaybeRef<'never' | 'always' | 'not-zero'>;
  min?: MaybeRef<number>;
  max?: MaybeRef<number>;
  initialValue?: number | null;
}

interface UseInputNumberReturn {
  modelValue: Ref<number | null>;
  readonly inputValue: Ref<string>;
}

export function useInputNumber(
  inputRef: Ref<HTMLInputElement>,
  options?: UseInputNumberOptions,
): UseInputNumberReturn {
  const modelValue = ref<number | null>(options?.initialValue ?? null);
  const inputValue = ref('');

  const { focused } = useFocus(inputRef);

  const decimal = computed(() => unref(options?.decimal ?? 'not-zero'));
  const precision = computed(() => unref(options?.precision ?? 2));
  const min = computed(() =>
    Math.max(unref(options?.min ?? Number.MIN_SAFE_INTEGER), Number.MIN_SAFE_INTEGER),
  );
  const max = computed(() =>
    Math.min(unref(options?.max ?? Number.MAX_SAFE_INTEGER), Number.MAX_SAFE_INTEGER),
  );
  const decimalSeparator = computed(() =>
    unref(options?.decimalSeparator ?? DEFAULT_DECIMAL_SEPARATOR),
  );
  const thousandSeparator = computed(() =>
    unref(options?.thousandSeparator ?? DEFAULT_THOUSAND_SEPARATOR),
  );

  const allowDecimals = computed(() => decimal.value === 'always' || decimal.value === 'not-zero');

  function setInputValue(value: string): void {
    if (inputRef.value) {
      inputRef.value.value = value;
    }

    inputValue.value = value;
  }

  function getSelection(): [number, number] {
    return [inputRef.value?.selectionStart ?? 0, inputRef.value?.selectionEnd ?? 0];
  }

  function setSelection(start: number, end?: number): void {
    inputRef.value?.setSelectionRange(start, end ?? start);
  }

  function syncInputValue(): void {
    if (modelValue.value === null) {
      setInputValue('');
      return;
    }

    setInputValue(
      formatNumber(modelValue.value, {
        decimalLimit: precision.value,
        decimalSeparator: decimalSeparator.value,
        thousandSeparator: thousandSeparator.value,
        zeroPadding: decimal.value === 'always',
      }),
    );
  }

  function handleInput(value: string): void {
    const [caretPosition] = getSelection();

    let restoreCaretPosition = true;
    let numberFound = false;
    let decimalFound = false;

    // Keep only numbers, decimal separator - the last in string
    const sanitizedValue = value
      .split('')
      .reverse()
      .reduce<string[]>((acc, symbol, i) => {
        // Allow numbers
        if (/^\d$/.test(symbol)) {
          numberFound = true;
          acc.push(symbol);
          return acc;
        }

        // Allow last decimal separator (including at the end if focused)
        if (!decimalFound && decimalSeparator.value === symbol && (numberFound || focused.value)) {
          decimalFound = true;
          acc.push(NUMBER_DECIMAL_SEPARATOR);
          return acc;
        }

        // Negative values
        if (
          min.value < 0 &&
          i === value.length - 1 &&
          (symbol === MINUS || symbol === CHAR_EN_DASH)
        ) {
          acc.push(MINUS);
          return acc;
        }

        return acc;
      }, [])
      .reverse()
      .join('');

    const [integer = '', decimals = ''] = sanitizedValue.split(NUMBER_DECIMAL_SEPARATOR);

    let newValue =
      integer || decimals
        ? parseFloat([integer, decimals.slice(0, precision.value)].join(NUMBER_DECIMAL_SEPARATOR))
        : null;

    // User started input with minus symbol
    if (sanitizedValue === MINUS && focused.value) {
      setInputValue(MINUS);
      modelValue.value = null;
      return;
    }

    // Value is null, nothing to do
    if (newValue === null) {
      setInputValue('');
      modelValue.value = null;
      return;
    }

    // Drop decimals
    if (!allowDecimals.value) {
      newValue = Math.floor(newValue);
    }

    // User is inputting something right now
    // and decimal separator is hanging without decimals or integer part:
    // .123
    // 123.
    if (
      focused.value &&
      allowDecimals.value &&
      (sanitizedValue.startsWith(NUMBER_DECIMAL_SEPARATOR) ||
        sanitizedValue.endsWith(NUMBER_DECIMAL_SEPARATOR))
    ) {
      setInputValue(
        [
          integer
            ? formatNumber(parseInt(integer, 10), {
                decimalLimit: 0,
                decimalSeparator: decimalSeparator.value,
                thousandSeparator: thousandSeparator.value,
                zeroPadding: false,
              })
            : '',
          decimalSeparator.value,
          decimals.slice(0, precision.value),
        ].join(''),
      );
    } else {
      // Prevent input more than max
      if (newValue > max.value) {
        newValue = max.value;
        restoreCaretPosition = false;
      }

      let zeroPadding = decimal.value === 'always';
      let decimalLimit =
        zeroPadding || (allowDecimals.value && decimals.length) ? precision.value : 0;

      // Allow values like 0,0 while user inputs
      if (
        focused.value &&
        allowDecimals.value &&
        decimals.length &&
        decimal.value !== 'always' &&
        decimals.endsWith('0')
      ) {
        decimalLimit = Math.min(precision.value, decimals.length);
        zeroPadding = true;
      }

      setInputValue(
        formatNumber(newValue, {
          decimalLimit,
          decimalSeparator: decimalSeparator.value,
          thousandSeparator: thousandSeparator.value,
          zeroPadding,
        }),
      );
    }

    if (restoreCaretPosition) {
      // Calculate how many numbers (+ decimal separator and minus sign) was before caret...
      let separatorFound = false;
      let minusFound = false;

      const numbersBefore = value
        .split('')
        .splice(0, caretPosition)
        .filter((symbol) => {
          if (/^\d$/.test(symbol)) {
            return true;
          }

          if (!minusFound && symbol === MINUS && min.value < 0) {
            minusFound = true;
            return true;
          }

          if (!separatorFound && decimalSeparator.value === symbol) {
            separatorFound = true;
            return true;
          }

          return false;
        }).length;

      let s = 0;
      let i = 0;

      // ...and keep the same
      while (s < numbersBefore && i < inputValue.value.length) {
        const symbol = inputValue.value[i];

        if (symbol === MINUS || /^\d$/.test(symbol) || decimalSeparator.value === symbol) {
          s++;
        }

        i++;
      }

      nextTick(() => {
        setSelection(i);
      });
    }

    // Emit correct value, but keep input until blur
    modelValue.value = Math.max(newValue, Math.max(min.value, Number.MIN_SAFE_INTEGER));
  }

  function onInput(event: InputEvent) {
    const value = (event.target as HTMLInputElement).value;
    handleInput(value);
  }

  function programmaticInput(value: string, caretPosition = 0): void {
    setInputValue(value);
    setSelection(caretPosition);
    handleInput(value);
  }

  const editing = refAutoReset(false, 100);

  function onKeydown(event: KeyboardEvent): void {
    if (event.ctrlKey || event.metaKey || event.altKey) {
      return;
    }

    const { value } = event.target as HTMLInputElement;

    const [integerPart = '', decimalPart = ''] = value.split(decimalSeparator.value);
    const [caretPosition, selectionEnd] = getSelection();
    const selectedRange = selectionEnd - caretPosition;

    if (event.key === Keys.Backspace) {
      editing.value = true;

      // Move through thousand separator
      if (value[caretPosition - 1] === thousandSeparator.value) {
        event.preventDefault();
        setSelection(caretPosition - 1);
        return;
      }

      // Delete separator with the latest decimal
      if (
        decimal.value !== 'always' && decimalPart.length && selectedRange > 0
          ? caretPosition === value.length - decimalPart.length && selectionEnd === value.length
          : caretPosition === value.length && decimalPart.length === 1
      ) {
        event.preventDefault();
        programmaticInput(integerPart, integerPart.length);
        return;
      }

      // Don't delete decimal separator if decimal exists
      if (
        decimal.value === 'always' &&
        decimalPart.length &&
        caretPosition === value.length - decimalPart.length
      ) {
        event.preventDefault();
        setSelection(caretPosition - 1);
        return;
      }

      return;
    }

    if (event.key === Keys.Delete) {
      editing.value = true;

      // Move through thousand separator
      if (value[caretPosition] === options?.thousandSeparator) {
        event.preventDefault();
        setSelection(caretPosition + 1);
        return;
      }

      // Delete separator with the latest decimal
      if (
        decimal.value !== 'always' && decimalPart.length && selectedRange > 0
          ? caretPosition === value.length - decimalPart.length && selectionEnd === value.length
          : caretPosition === value.length - 1 && decimalPart.length === 1
      ) {
        event.preventDefault();
        programmaticInput(integerPart, integerPart.length);
        return;
      }

      // Don't delete decimal separator if decimal exists
      if (
        decimal.value === 'always' &&
        decimalPart.length &&
        caretPosition === value.length - decimalPart.length - 1
      ) {
        event.preventDefault();
        setSelection(caretPosition + 1);
        return;
      }

      return;
    }

    // Handle decimal separator
    if ([NUMBER_DECIMAL_SEPARATOR, ',', decimalSeparator.value].includes(event.key)) {
      editing.value = true;

      // No decimals
      if (!allowDecimals.value) {
        event.preventDefault();
        return;
      }

      // Jump to decimals if separator is already inserted
      if (decimalSeparator.value && value.includes(decimalSeparator.value) && !selectedRange) {
        event.preventDefault();
        setSelection(value.length - decimalPart.length);
        return;
      }

      // Insert leading zero and decimal separator
      if ((caretPosition === 0 && !value) || selectedRange === value.length) {
        event.preventDefault();
        programmaticInput(`0${decimalSeparator.value}`, 2);
        return;
      }

      if (event.key !== decimalSeparator.value) {
        event.preventDefault();

        programmaticInput(
          [
            value.slice(0, caretPosition),
            options?.decimalSeparator,
            value.slice(selectionEnd),
          ].join(''),
          caretPosition + 1,
        );
        return;
      }

      return;
    }

    // Handle minus
    if (MINUS_SYMBOLS.includes(event.key)) {
      editing.value = true;

      event.preventDefault();

      if (min.value >= 0) {
        return;
      }

      if (
        selectedRange === value.length ||
        (caretPosition === 0 && (value[0] !== MINUS || selectionEnd >= 1))
      ) {
        programmaticInput(`${MINUS}${value.slice(selectionEnd)}`, 1);
      }

      return;
    }

    // Handle numbers
    if (/^\d$/.test(event.key)) {
      editing.value = true;

      // Prevent input more than one leading zero
      if (event.key === '0' && value.charAt(0) === '0' && caretPosition <= 1) {
        event.preventDefault();
        setSelection(1);
        return;
      }

      // Replace leading zero
      if (value === '0' && caretPosition > 0) {
        event.preventDefault();
        programmaticInput(event.key, caretPosition);
        return;
      }

      // Limit decimals length
      if (
        decimalPart.length &&
        decimalPart.length >= precision.value &&
        caretPosition === value.length
      ) {
        event.preventDefault();
      }

      return;
    }

    // Disable other symbols
    if (event.key.length === 1) {
      event.preventDefault();
    }
  }

  watch([thousandSeparator, decimalSeparator], () => {
    syncInputValue();
  });

  watch(
    modelValue,
    (value, oldValue) => {
      if (value === oldValue) {
        return;
      }
      // Don't sync values while editing. It's needed if we have "hanging" parts (minus, decimal)
      if (focused.value && editing.value) {
        return;
      }
      syncInputValue();
    },
    { immediate: true },
  );

  watch(inputRef, () => {
    setInputValue(inputValue.value);
  });

  useEventListener(inputRef, 'input', onInput);
  useEventListener(inputRef, 'keydown', onKeydown);
  useEventListener(inputRef, 'blur', syncInputValue);

  return { modelValue, inputValue };
}
