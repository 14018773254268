<script lang="ts" setup>
import { ref } from 'vue';
import { Keys } from '../../shared/enums';
import { useChildrenFocusControl } from '../../composables';
import { injectStrict } from '../../utils';
import { OB_TABS_CONTEXT } from './shared';

const rootRef = ref();
const { focusFirst, focusLast, focusNext, focusPrevious } = useChildrenFocusControl({
  root: rootRef,
});

function onKeydown(event: KeyboardEvent) {
  switch (event.key) {
    case Keys.ArrowLeft: {
      event.preventDefault();
      event.stopPropagation();
      focusPrevious();
      break;
    }
    case Keys.ArrowRight: {
      event.preventDefault();
      event.stopPropagation();
      focusNext();
      break;
    }
    case Keys.Home: {
      event.preventDefault();
      event.stopPropagation();
      focusFirst();
      break;
    }
    case Keys.End: {
      event.preventDefault();
      event.stopPropagation();
      focusLast();
      break;
    }
    default: {
      break;
    }
  }
}

const { variant } = injectStrict(
  OB_TABS_CONTEXT,
  undefined,
  '<ObTabsList /> must be a child of <ObTabs /> component.',
);
</script>

<template>
  <div
    ref="rootRef"
    :class="variant === 'secondary' ? $style.secondary : $style.primary"
    role="tablist"
    aria-orientation="horizontal"
    @keydown="onKeydown"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
@use '../../styles/shared';

.primary {
  @include shared.scrollbar();
  overflow-y: auto;
  display: flex;
  gap: 24px;
}

.secondary {
  display: inline-flex;
  vertical-align: top;
}
</style>
