<script lang="ts" setup>
import { h, useSlots, useAttrs, mergeProps, computed } from 'vue';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-vue';
import { ObTooltip } from '../tooltip';
import type { DataTableColumnAlign, DataTableSorting } from './types';
import style from './ob-data-table.module.scss';

type Props = {
  align?: DataTableColumnAlign;
  columnKey: string;
  description?: string;
  sortable?: boolean;
  sorting?: DataTableSorting;
};

defineOptions({ inheritAttrs: false });

const props = withDefaults(defineProps<Props>(), {
  align: 'start',
  description: undefined,
  sortable: false,
  sorting: undefined,
});

const slots = useSlots();
const attrs = useAttrs();

const sortActive = computed(() => props.sorting?.sortBy === props.columnKey);

defineRender(() => {
  const cell = h(
    'div',
    mergeProps(attrs, {
      class: [
        style.cell,
        style.headCell,
        {
          [style.cellAlignCenter]: props.align === 'center',
          [style.cellAlignEnd]: props.align === 'end',
          [style.cellAlignStart]: props.align === 'start',
          [style.headCellSortable]: props.sortable,
          [style.headCellSortActive]: sortActive.value,
        },
      ],
    }),
    [
      h('div', { class: style.headCellContent }, slots.default && slots.default()),
      props.sortable
        ? h(
            'div',
            { class: style.headCellIcon },
            h(
              !sortActive.value || props.sorting?.sortOrder === 'desc'
                ? IconChevronDown
                : IconChevronUp,
            ),
          )
        : null,
    ],
  );

  if (props.description) {
    return h(
      ObTooltip,
      { placement: 'top', activateDelay: 200 },
      {
        // TODO: Tooltip has defineSlots but it doesn't help in this case. Better than `any` workaround?
        host: ({ hostProps }: any) => h(cell, hostProps),
        default: () => props.description,
      },
    );
  }

  return cell;
});
</script>
