import { computed } from 'vue';
import type { Ref } from 'vue';
import { unrefElement, useEventListener } from '@vueuse/core';
import type { MaybeElementRef } from '@vueuse/core';
import { focusable } from 'tabbable';

// TODO: useTypeaheadFocus - move focus to item that has label (?) matching with typed letter

function useMoveFocusToMenuItem(
  open: Ref<boolean>,
  hostRef: MaybeElementRef,
  containerRef: MaybeElementRef,
) {
  const hostElement = computed(() => unrefElement(hostRef));
  const containerElement = computed(() => unrefElement(containerRef));

  function onHostKeydown(event: KeyboardEvent) {
    if (!open.value || !containerElement.value) {
      return;
    }

    const focusableElements = focusable(containerElement.value);

    if (event.key === 'ArrowDown') {
      focusableElements[0]?.focus();
      return;
    }

    if (event.key === 'ArrowUp') {
      focusableElements[focusableElements.length - 1]?.focus();
      return;
    }
  }

  useEventListener(hostElement, 'keydown', onHostKeydown);
}

function useCloseMenuOnTab(
  open: Ref<boolean>,
  hostRef: MaybeElementRef,
  containerRef: MaybeElementRef,
  onClose: () => void,
): void {
  const containerElement = computed(() => unrefElement(containerRef));
  const hostElement = computed(() => unrefElement(hostRef));

  function onTab(event: KeyboardEvent) {
    if (open.value && event.key === 'Tab') {
      onClose?.();
    }
  }

  useEventListener(containerElement, 'keydown', onTab);
  useEventListener(hostElement, 'keydown', onTab);
}

export function useMenuKeyboardNavigation(
  open: Ref<boolean>,
  hostRef: MaybeElementRef,
  containerRef: MaybeElementRef,
  onClose: () => void,
): void {
  useMoveFocusToMenuItem(open, hostRef, containerRef);
  useCloseMenuOnTab(open, hostRef, containerRef, onClose);
}
