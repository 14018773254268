import { get } from 'lodash-es';

export function getValueByKey<T = unknown, R = unknown>(
  target: T,
  key: string | ((item: T) => R),
): R | undefined {
  if (typeof key === 'function') {
    return key(target);
  }

  if (typeof key === 'string') {
    return get(target, key);
  }

  return undefined;
}
