<script lang="ts" setup>
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { IconCalendarEvent } from '@tabler/icons-vue';
import { formatISO, parseISO } from 'date-fns';
import { isObject } from 'lodash-es';
import { useConfig } from '../../composables';
import { ObCalendar } from '../calendar';
import { ObFloatingSurface } from '../floating-surface';
import { ObSpace } from '../space';
import { ObButton } from '../button';
import { CHAR_EN_DASH } from '../../shared/constants';

type ModelValue = string | { start: string | null; end: string | null } | null;
type SelectionMode = 'single' | 'range';
type Shortcut = { label: string; value: ModelValue };

interface Props {
  currentDate?: string;
  disabled?: boolean;
  modelValue?: ModelValue;
  minDate?: string | null;
  maxDate?: string | null;
  selectionMode?: SelectionMode;
  shouldDisableDate?: (date: string) => boolean;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6; // TODO: from global config
  label?(
    value: string | null | { start: string | null; end: string | null },
    selectionMode: 'single' | 'range',
  ): string;
  shortcuts?: Shortcut[];
}

const props = withDefaults(defineProps<Props>(), {
  currentDate: undefined,
  disabled: false,
  modelValue: null,
  minDate: undefined,
  maxDate: undefined,
  selectionMode: 'single',
  shouldDisableDate: undefined,
  weekStartsOn: 1,
  label: undefined,
  shortcuts: () => [],
});

const emit = defineEmits<{
  'update:modelValue': [value: ModelValue];
  'update:currentDate': [value: string];
}>();

const currentDate = useVModel(props, 'currentDate', emit, {
  passive: true,
});

const modelValue = useVModel(props, 'modelValue', emit, {
  passive: true,
});

function formatDate(value: string) {
  return formatISO(parseISO(value), { representation: 'date' });
}

function formatLabel(value: ModelValue, selectionMode: SelectionMode) {
  if (selectionMode === 'range' && isObject(value)) {
    const { start, end } = value;

    if (start && end) {
      return `${formatDate(start)} ${CHAR_EN_DASH} ${formatDate(end)}`;
    }

    if (start) {
      return `${formatDate(start)} ${CHAR_EN_DASH} ...`;
    }

    return 'Select dates';
  }

  if (value && !isObject(value)) {
    return formatDate(value);
  }

  return 'Select date'; // TODO: i18n, props + global config
}

const config = useConfig();

const label = computed(() => {
  if (typeof props.label === 'function') {
    return props.label(modelValue.value, props.selectionMode);
  }

  if (config?.value?.i18n?.DatePicker?.label) {
    return config?.value?.i18n?.DatePicker?.label(modelValue.value, props.selectionMode);
  }

  return formatLabel(modelValue.value, props.selectionMode);
});

const shortcuts = computed(() => {
  return props.shortcuts.map(({ label, value }) => {
    let selected = false;

    if (typeof value === 'string') {
      selected = value === modelValue.value;
    } else if (typeof modelValue.value === 'object') {
      selected = modelValue.value?.start === value?.start && modelValue.value?.end === value?.end;
    }

    return {
      label,
      value,
      selected,
    };
  });
});
</script>

<template>
  <ObFloatingSurface width="auto">
    <template #host="{ active }">
      <ObButton :active="active" :disabled="props.disabled" variant="secondary">
        <IconCalendarEvent />
        {{ label }}
      </ObButton>
    </template>
    <div :class="$style.root">
      <div v-if="shortcuts.length" :class="$style.shortcuts">
        <ObSpace vertical spacing="4" align-x="start">
          <ObButton
            v-for="shortcut in shortcuts"
            :key="shortcut.label"
            :active="shortcut.selected"
            variant="tertiary"
            @click="modelValue = shortcut.value"
          >
            {{ shortcut.label }}
          </ObButton>
        </ObSpace>
      </div>
      <div :class="$style.calendar">
        <ObCalendar
          v-model="modelValue"
          v-model:current-date="currentDate"
          :selection-mode="props.selectionMode"
          :week-starts-on="props.weekStartsOn"
          :should-disable-date="props.shouldDisableDate"
          :min-date="props.minDate"
          :max-date="props.maxDate"
        />
      </div>
    </div>
  </ObFloatingSurface>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';
@use '../../styles/shared';

.root {
  display: flex;
}

.calendar {
  padding: 12px;
}

.shortcuts {
  padding: 12px;
  border-right: 1px solid #f0f1f4; // TODO: use design token
}

.shortcut {
  // TODO: use design tokens
  @include shared.reset-button();
  background: #f9f9ff;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: shared.$border-radius-s;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  height: 32px;
  padding: 4px 8px;
}
</style>
