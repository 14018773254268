<script lang="ts" setup>
import { computed } from 'vue';
import { BreakpointBasedProp } from '../../shared/types';
import { breakpointBasedPropToClassNames } from '../../utils';
import type {
  FlexGridAlignContent,
  FlexGridAlignItems,
  FlexGridDirection,
  FlexGridJustifyContent,
  FlexGridWrap,
  FlexGridSpacing,
} from './types';

type ObFlexGridProps = {
  alignContent?: BreakpointBasedProp<FlexGridAlignContent>;
  alignItems?: BreakpointBasedProp<FlexGridAlignItems>;
  direction?: BreakpointBasedProp<FlexGridDirection>;
  justifyContent?: BreakpointBasedProp<FlexGridJustifyContent>;
  spacing?: BreakpointBasedProp<FlexGridSpacing>;
  spacingX?: BreakpointBasedProp<FlexGridSpacing>;
  spacingY?: BreakpointBasedProp<FlexGridSpacing>;
  wrap?: BreakpointBasedProp<FlexGridWrap>;
};

const props = withDefaults(defineProps<ObFlexGridProps>(), {
  alignContent: undefined,
  alignItems: undefined,
  direction: undefined,
  justifyContent: undefined,
  spacing: undefined,
  spacingX: undefined,
  spacingY: undefined,
  wrap: undefined,
});

const classes = computed(() => [
  'ob-flex-grid',
  ...breakpointBasedPropToClassNames(props.alignContent, 'align-content'),
  ...breakpointBasedPropToClassNames(props.alignItems, 'align-items'),
  ...breakpointBasedPropToClassNames(props.direction, 'direction'),
  ...breakpointBasedPropToClassNames(props.justifyContent, 'justify-content'),
  ...breakpointBasedPropToClassNames(props.spacing, 'spacing'),
  ...breakpointBasedPropToClassNames(props.spacingX, 'spacing-x'),
  ...breakpointBasedPropToClassNames(props.spacingY, 'spacing-y'),
  ...breakpointBasedPropToClassNames(props.wrap),
]);
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>
