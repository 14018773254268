import { ref, watch, computed, onBeforeUnmount } from 'vue';
import type { Ref, ComputedRef } from 'vue';

interface UseActivatableOptions<P extends object, K extends keyof P> {
  key?: K;
  eventName?: string;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onDeactivated?: () => void;
}

interface UseActivatableReturn {
  activate: () => void;
  deactivate: () => void;
  toggle: () => void;
  completeDeactivation: () => void;
  active: Ref<boolean>;
  deactivating: Ref<boolean>;
  maybeActive: ComputedRef<boolean>;
}

export function useActivatable<P extends object, K extends keyof P, E extends string>(
  props: P,
  emit: (event: E, ...args: any[]) => void,
  options?: UseActivatableOptions<P, K>,
): UseActivatableReturn {
  const key = options?.key ?? ('active' as K);
  const eventName = (options?.eventName ?? `update:${key.toString()}`) as E;

  const active = ref(false);
  const deactivating = ref(false);

  function completeDeactivation() {
    deactivating.value = false;

    if (typeof options?.onDeactivated === 'function') {
      options.onDeactivated();
    }
  }

  function activate() {
    if (active.value) {
      return;
    }

    active.value = true;

    emit(eventName, true);
    emit('activated' as E);

    if (typeof options?.onActivate === 'function') {
      options.onActivate();
    }
  }

  function deactivate() {
    if (!active.value) {
      return;
    }

    active.value = false;
    deactivating.value = true;

    emit(eventName, false);
    emit('deactivated' as E);

    if (typeof options?.onDeactivate === 'function') {
      options.onDeactivate();
    }
  }

  function toggle() {
    if (active.value) {
      deactivate();
      return;
    }

    activate();
  }

  const maybeActive = computed(() => active.value || deactivating.value);

  watch(
    () => props[key],
    (value) => {
      if (value) {
        activate();
        return;
      }

      deactivate();
    },
    { immediate: true },
  );

  onBeforeUnmount(() => deactivate());

  return {
    activate,
    deactivate,
    toggle,
    completeDeactivation,
    active,
    deactivating,
    maybeActive,
  };
}
