import { computed, unref, watch } from 'vue';
import type { ComputedRef, MaybeRef } from 'vue';
import { toString } from 'lodash-es';
import { getValueByKey, areValuesEqual } from '../../utils';

interface UseOptionsListOptions<T = unknown, V = unknown> {
  items: MaybeRef<T[]>;
  optionLabel?: string | ((option: T) => string);
  optionsLimit?: number;
  optionValue?: string | ((option: T) => V);
  searchText?: MaybeRef<string>;
  selectedItems: MaybeRef<V[]>;
  trackValueBy?: string | ((value: unknown) => unknown);
}

export interface UseOptionsListOption<V = unknown> {
  label: string;
  value: V | undefined;
  key: string;
  selected: boolean;
}

interface UseOptionsListReturn<T = unknown, V = unknown> {
  options: ComputedRef<UseOptionsListOption<V>[]>;
  normalizeForTextSearch: (value: unknown) => string;
  selectedOptions: ComputedRef<UseOptionsListOption<V>[]>;
  selectedOption: ComputedRef<UseOptionsListOption<V>>;
  getOptionValue: (option: T) => V | undefined;
  getOptionLabel: (option: T) => string;
}

export function useOptionsList<T = unknown, V = unknown>(
  options: UseOptionsListOptions<T, V>,
): UseOptionsListReturn<T, V> {
  const items = computed(() => unref(options.items));
  const searchText = computed(() => unref(options.searchText));
  const selectedItems = computed(() => unref(options.selectedItems));

  function normalizeForTextSearch(value: unknown): string {
    return toString(value).toLowerCase().trim();
  }

  function getOptionValue(option: T): V | undefined {
    return options.optionValue
      ? getValueByKey<T, V>(option, options.optionValue)
      : (option as unknown as V);
  }

  function getOptionLabel(option: T): string {
    return toString(options.optionLabel ? getValueByKey(option, options.optionLabel) : option);
  }

  function findOptionByValue(optionsList: T[], value: V): T | undefined {
    return optionsList.find((option) => {
      return areValuesEqual(value, getOptionValue(option), options.trackValueBy);
    });
  }

  function isOptionSelected(option: T): boolean {
    if (!selectedItems.value?.length) {
      return false;
    }

    const value = getOptionValue(option);

    return selectedItems.value.some((item: unknown) =>
      areValuesEqual(value, item, options.trackValueBy),
    );
  }

  const filteredOptions = computed(() => {
    let result = [];

    if (!searchText.value) {
      result = items.value;
    } else {
      const normalizedSearch = normalizeForTextSearch(searchText.value);

      result = items.value.filter((option) => {
        const label = normalizeForTextSearch(getOptionLabel(option));
        return label.includes(normalizedSearch);
      });
    }

    return result.slice(0, options.optionsLimit ?? 300);
  });

  let cachedSelectedOptions: UseOptionsListOption<V>[] = [];

  const selectedOptions = computed<UseOptionsListOption<V>[]>(() => {
    return selectedItems.value.map((value, index) => {
      const key = toString(
        (options.trackValueBy && getValueByKey(value, options.trackValueBy)) ?? index,
      );

      // There are 2 options for value selection.

      // 1. Select whole option. In this case we don't need to search selected option.
      if (!options.optionValue) {
        return {
          label: getOptionLabel(value as unknown as T),
          value,
          key,
          selected: true,
        };
      }

      // 2. Select property of option (or result of function called with option as an argument).
      // Need to find option with value equals to selected value in options list.
      // Maybe it exists in options list, maybe it was cached to not lose when options changes if external search is used.
      const selectedOption = findOptionByValue(items.value, value);

      if (selectedOption) {
        return {
          label: getOptionLabel(selectedOption),
          value,
          key,
          selected: true,
        };
      }

      // If selected option was not found it's not possible to construct correct option object.
      // So, we just return value as is.

      return (
        cachedSelectedOptions.find((item) =>
          areValuesEqual(item.value, value, options.trackValueBy),
        ) ?? {
          label: toString(value),
          value,
          key,
          selected: true,
        }
      );
    });
  });

  const selectedOption = computed(() => selectedOptions.value[0]);

  watch(
    selectedOptions,
    (value) => {
      cachedSelectedOptions = value;
    },
    { deep: true },
  );

  return {
    options: computed(() =>
      filteredOptions.value.map((option, index) => {
        return {
          label: getOptionLabel(option),
          value: getOptionValue(option),
          key: toString(
            (options.trackValueBy && getValueByKey(option, options.trackValueBy)) ?? index,
          ),
          selected: isOptionSelected(option),
        };
      }),
    ),
    selectedOptions,
    selectedOption,
    getOptionValue,
    getOptionLabel,
    normalizeForTextSearch,
  };
}
