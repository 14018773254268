<script lang="ts" setup>
import { computed } from 'vue';
import { isNil } from 'lodash-es';
import { IconX } from '@tabler/icons-vue';
import { pxOrValue } from '../../utils';

interface Props {
  clickable?: boolean;
  maxWidth?: string | number;
  removable?: boolean;
  removerAttributes?: object;
  role?: 'button' | 'link';
}

const props = withDefaults(defineProps<Props>(), {
  clickable: false,
  maxWidth: undefined,
  removable: false,
  removerAttributes: undefined,
  role: 'button',
});

const emit = defineEmits<{
  remove: [];
}>();

const maxWidth = computed(() => (!isNil(props.maxWidth) ? pxOrValue(props.maxWidth) : undefined));
</script>

<template>
  <span
    :class="[$style.root, { [$style.clickable]: props.clickable }]"
    :role="props.clickable ? props.role : undefined"
    :style="{ maxWidth }"
    :tabindex="props.clickable ? '0' : undefined"
  >
    <span :class="$style.text"><slot /></span>
    <button
      v-if="props.removable"
      aria-label="Remove"
      :class="$style.remover"
      type="button"
      v-bind="removerAttributes"
      @click.prevent.stop="emit('remove')"
    >
      <span :class="$style.removerIcon">
        <IconX aria-hidden="true" />
      </span>
    </button>
  </span>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: colors.$primary-80;
  border: 1px solid #b4b7e8; // TODO: design token
  background: #f8f7fe; // TODO: design token
  padding: 0 4px;
  height: 20px;
  border-radius: shared.$border-radius-s;
  max-width: 144px;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.text {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  font-family: typography.$font-family-primary;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remover {
  @include shared.reset-button();
  display: flex;
  align-items: center;
  flex: none;
  height: 100%;
  margin-left: 4px;
  margin-right: -4px;
  padding-right: 4px;

  &:hover {
    opacity: 0.75;
  }

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.removerIcon {
  font-size: 14px;
  width: 1em;
  height: 1em;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    background: #f2f0fe; // TODO: design token
  }

  &:active {
    background: #dad4fc; // TODO: design token
  }
}
</style>
