import { CHAR_NO_BREAK_SPACE } from '../../shared/constants';
import { getNumberDecimalsPart } from './get-number-decimals-part';

/**
 * Formats number adding a thousand separators and correct decimal separator
 * padding decimal part with zeroes to given length
 */

type FormatNumberOptions = {
  decimalLimit?: number;
  decimalSeparator?: string;
  thousandSeparator?: string;
  zeroPadding?: boolean;
};

const defaults: FormatNumberOptions = {
  decimalSeparator: ',',
  thousandSeparator: CHAR_NO_BREAK_SPACE,
  zeroPadding: false,
};

export function formatNumber(value: number, options?: FormatNumberOptions): string {
  const { decimalLimit, decimalSeparator, thousandSeparator, zeroPadding } = {
    ...defaults,
    ...options,
  };

  const integerPart = String(Math.floor(Math.abs(value)));

  let decimals = getNumberDecimalsPart(value, decimalLimit);

  if (decimalLimit && zeroPadding) {
    const zeroPaddingSize: number = zeroPadding ? Math.max(decimalLimit - decimals.length, 0) : 0;
    const zeroPartString = '0'.repeat(zeroPaddingSize);

    decimals = `${decimals}${zeroPartString}`;
  }

  const remainder = integerPart.length % 3;
  const sign = value < 0 ? '-' : '';

  let result = sign + integerPart.charAt(0);

  for (let i = 1; i < integerPart.length; i++) {
    if (i % 3 === remainder && integerPart.length > 3) {
      result += thousandSeparator;
    }

    result += integerPart.charAt(i);
  }

  return decimalLimit && !!decimals ? result + decimalSeparator + decimals : result;
}
