<script lang="ts" setup>
import { useFocusVisible } from '../../composables';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  focused?: boolean;
  hovered?: boolean;
  indeterminate?: boolean;
  invalid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  checked: false,
  disabled: false,
  focused: false,
  hovered: false,
  indeterminate: false,
  invalid: false,
});

const focusVisible = useFocusVisible();
</script>

<template>
  <span
    :class="[
      $style.root,
      {
        [$style.disabled]: props.disabled,
        [$style.focused]: props.focused && focusVisible,
        [$style.hovered]: props.hovered,
      },
    ]"
  >
    <svg viewBox="0 0 16 16" fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM14 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1Z"
      />
      <path
        v-if="props.indeterminate"
        d="M3.5 8.5H12.5C12.7761 8.5 13 8.27614 13 8C13 7.72386 12.7761 7.5 12.5 7.5H3.5C3.22386 7.5 3 7.72386 3 8C3 8.27614 3.22386 8.5 3.5 8.5Z"
      />
      <path
        v-else-if="props.checked"
        d="M12.5004 4.00019C12.3743 4.00363 12.2547 4.05186 12.1668 4.13467L6.17465 10.9278L3.83535 8.79911C3.79063 8.75648 3.73708 8.72244 3.67782 8.699C3.61856 8.67555 3.55478 8.66316 3.49022 8.66256C3.42567 8.66196 3.36163 8.67316 3.30186 8.6955C3.24209 8.71784 3.18779 8.75086 3.14214 8.79265C3.09649 8.83444 3.0604 8.88415 3.036 8.93887C3.0116 8.99358 2.99937 9.0522 3.00003 9.1113C3.00068 9.1704 3.01421 9.22878 3.03983 9.28303C3.06544 9.33728 3.10262 9.3863 3.14919 9.42724L5.83157 11.87C5.92257 11.9532 6.04598 12 6.17465 12C6.30332 12 6.42672 11.9532 6.51773 11.87L12.853 4.76279C12.9231 4.7004 12.971 4.62007 12.9904 4.53233C13.0098 4.44459 12.9997 4.35354 12.9616 4.2711C12.9235 4.18867 12.8591 4.11869 12.7768 4.07035C12.6945 4.02201 12.5982 3.99755 12.5004 4.00019Z"
      />
    </svg>
  </span>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  color: colors.$primary;

  svg {
    position: relative;
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  &.focused::before {
    @include shared.centerer();
    content: '';
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 1px colors.$hyperlink;
    border-radius: 4px;
  }

  &.hovered {
    background-color: #f2f0fe;
  }

  &.disabled {
    color: colors.$surface-40;
    cursor: not-allowed;
  }
}
</style>
