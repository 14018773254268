<script lang="ts" setup generic="T">
import { provide, inject, computed, toValue, useCssModule } from 'vue';
import { SlickList } from 'vue-slicksort';
import { AriaRole } from '../../../shared/types';
import { ACTION_LIST_CONTAINER_CONTEXT } from './action-list-container-context';
import { ACTION_LIST_CONTEXT } from './action-list-context';
import ObActionListItem from './ob-action-list-item.vue';

interface Props {
  role?: AriaRole;
  selectionMode?: 'single' | 'multiple';
  compact?: boolean;
  sortable?: boolean;
  onAfterSelect?: () => void;
  items?: T[];
}

const props = withDefaults(defineProps<Props>(), {
  role: undefined,
  selectionMode: undefined,
  onAfterSelect: undefined,
  compact: false,
  sortable: false,
  items: () => [],
});

const emit = defineEmits<{
  'update:items': [items: T[]];
}>();

const { selectionMode, onAfterSelect, listRole, itemRole, compact } = inject(
  ACTION_LIST_CONTAINER_CONTEXT,
  {},
);

provide(ACTION_LIST_CONTEXT, {
  selectionMode: computed(() => props.selectionMode ?? toValue(selectionMode)),
  compact: computed(() => props.compact ?? toValue(compact)),
  itemRole: computed(() => toValue(itemRole)),
  sortable: computed(() => props.sortable),
  onAfterSelect: props.onAfterSelect ?? onAfterSelect,
});

const role = computed(() => props.role || toValue(listRole));

const list = computed({
  get() {
    return [...props.items];
  },
  set(value: T[]) {
    emit('update:items', value);
  },
});

const style = useCssModule();

const sortableAttributes = computed(() => ({
  tag: 'ul',
  list: list.value,
  'onUpdate:list': (value: T[]) => (list.value = value),
  useDragHandle: true,
  helperClass: props.sortable ? style.draggingItem : undefined,
  lockAxis: 'y',
  draggedSettlingDuration: 1, // avoid flickering when release drag
}));
</script>

<template>
  <component
    :is="props.sortable ? SlickList : 'ul'"
    :role="role"
    :class="$style.root"
    v-bind="props.sortable ? sortableAttributes : null"
  >
    <slot>
      <template v-for="(item, index) in list">
        <slot name="item" v-bind="{ item, index }">
          <ObActionListItem :key="index" :index="index">
            <template #icon>
              <slot name="item-icon" />
            </template>
            <slot name="item-label" />
            <template #description>
              <slot name="item-description" />
            </template>
          </ObActionListItem>
        </slot>
      </template>
    </slot>
  </component>
</template>

<style lang="scss" module>
.root {
  margin: 0;
  padding: 0 0 0 10px;
}

.draggingItem {
  z-index: 9999;
  cursor: move;
  pointer-events: auto !important;
}
</style>
