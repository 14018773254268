<script lang="ts" setup>
import type { Elevation } from '../../shared/types';
import { hasSlotContent } from '../../utils';
import { ObPane } from '../pane';

interface ObPaneProps {
  elevation?: Elevation;
  selected?: boolean;
  hoverable?: boolean;
}

const props = withDefaults(defineProps<ObPaneProps>(), {
  elevation: '0',
  selected: false,
  hoverable: false,
});
</script>

<template>
  <ObPane
    class="ob-card"
    :selected="props.selected"
    :hoverable="props.hoverable"
    :elevation="elevation"
  >
    <div v-if="hasSlotContent($slots.header)" class="ob-card__header">
      <slot name="header" />
    </div>
    <div class="ob-card__body">
      <slot />
    </div>
    <div v-if="hasSlotContent($slots.footer)" class="ob-card__footer">
      <slot name="footer" />
    </div>
  </ObPane>
</template>

<style lang="scss">
@use '../../styles/colors';

.ob-card {
  overflow: hidden;

  &__header,
  &__footer {
    padding: 12px 24px;
  }

  &__header {
    background: #f8f7fe; // TODO: use token
  }

  &__body {
    padding: 24px;
  }

  &__footer {
    border-top: 1px solid colors.$surface-6;
  }
}
</style>
