<script lang="ts" setup>
import { provide, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import { OB_ABSTRACT_DISCLOSURE_CONTEXT } from './shared';

interface ObAbstractDisclosureProps {
  expanded?: boolean;
}

const props = withDefaults(defineProps<ObAbstractDisclosureProps>(), {
  expanded: false,
});

const emit = defineEmits<{
  'update:expanded': [active: boolean];
}>();

const expanded = useVModel(props, 'expanded', emit, {
  passive: true,
});

function expand() {
  if (expanded.value) {
    return;
  }
  expanded.value = true;
}

function collapse() {
  if (!expanded.value) {
    return;
  }
  expanded.value = false;
}

function toggle() {
  expanded.value = !expanded.value;
}

const buttonId = ref(null);
const panelId = ref(null);
const buttonRef = ref<HTMLElement | null>(null);
const panelRef = ref<HTMLElement | null>(null);

provide(OB_ABSTRACT_DISCLOSURE_CONTEXT, {
  expanded,
  expand,
  collapse,
  toggle,
  buttonId,
  panelId,
  buttonRef,
  panelRef,
});
</script>

<template>
  <slot v-bind="{ expanded, expand, collapse, toggle }" />
</template>
