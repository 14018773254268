<script lang="ts" setup>
import style from './ob-data-table.module.scss';

type Props = {
  selected?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  selected: false,
});
</script>

<template>
  <div
    :class="[
      style.row,
      {
        [style.rowSelected]: props.selected,
      },
    ]"
  >
    <div v-if="$slots.pinnedStart" :class="[style.pinned, style.pinnedStart]">
      <slot name="pinnedStart" />
    </div>
    <slot />
    <div v-if="$slots.pinnedEnd" :class="[style.pinned, style.pinnedEnd]">
      <slot name="pinnedEnd" />
    </div>
  </div>
</template>
