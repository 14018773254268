import { ref, computed, type ComputedRef } from 'vue';
import { createSharedComposable, useEventListener } from '@vueuse/core';

// This composable returns focus visibility state.
// Helps to implement behavior like :focus-visible CSS pseudo-class
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
export const useFocusVisible = createSharedComposable((): ComputedRef<boolean> => {
  const focusVisible = ref(false);
  let pointerFocus = false;
  let pointerFocusTimeout: ReturnType<typeof setTimeout>;

  function onDocumentKeydown(): void {
    pointerFocus = false;
  }

  function onDocumentClick(event: MouseEvent): void {
    if (!event.detail) {
      return;
    } // enter/space on focused element

    pointerFocus = true;

    clearTimeout(pointerFocusTimeout);

    pointerFocusTimeout = setTimeout(() => {
      pointerFocus = false;
    }, 600);
  }

  function onDocumentFocusIn(): void {
    focusVisible.value = !pointerFocus;
  }

  function onWindowBlur(): void {
    const fix = (): void => {
      pointerFocus = true;
      setTimeout(() => {
        pointerFocus = false;
      });
      window.removeEventListener('focus', fix);
    };
    window.addEventListener('focus', fix);
  }

  useEventListener(document.documentElement, 'pointerdown', onDocumentClick, true);
  useEventListener(document.documentElement, 'pointerup', onDocumentClick, true);
  useEventListener(document.documentElement, 'mousedown', onDocumentClick, true);
  useEventListener(document.documentElement, 'mouseup', onDocumentClick, true);
  useEventListener(document.documentElement, 'focusin', onDocumentFocusIn);
  useEventListener(document.documentElement, 'keydown', onDocumentKeydown);
  useEventListener(window, 'blur', onWindowBlur);

  return computed(() => focusVisible.value);
});
