<script lang="ts" setup>
import { computed } from 'vue';
import { isNil } from 'lodash-es';
import { pxOrValue } from '../../utils';

interface Props {
  size?: string | number;
}

const props = withDefaults(defineProps<Props>(), {
  size: undefined,
});

const styles = computed(() => ({
  height: !isNil(props.size) ? pxOrValue(props.size) : undefined,
}));
</script>

<template>
  <div :class="$style.root" role="progressbar" :style="styles">
    <div :class="$style.bar" />
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';

@keyframes progress {
  0% {
    transform: translate3d(-35%, 0, 0) scale3d(0.35, 1, 1);
  }
  60% {
    transform: translate3d(100%, 0, 0) scale3d(0.9, 1, 1);
  }
  to {
    transform: translate3d(100%, 0, 0) scale3d(0.9, 1, 1);
  }
}

@keyframes progressShort {
  0% {
    transform: translate3d(-101%, 0, 0) scaleZ(1);
  }
  60% {
    transform: translate3d(107%, 0, 0) scale3d(0.01, 1, 1);
  }
  to {
    transform: translate3d(107%, 0, 0) scale3d(0.01, 1, 1);
  }
}

.root {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 4px;
  color: colors.$active;

  &::before {
    @include shared.coverer();
    content: '';
    background-color: currentColor;
    opacity: 0.25;
  }
}

.bar {
  @include shared.coverer();

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: 0 0;
    background-color: currentColor;
  }

  &::before {
    animation: progress 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }

  &::after {
    transform: translate3d(-101%, 0, 0) scaleZ(1);
    animation: progressShort 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
  }
}
</style>
