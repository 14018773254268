<script lang="ts" setup>
import { computed, watch, toRef } from 'vue';
import { useOffsetPagination } from '@vueuse/core';
import { IconCircleChevronLeft, IconCircleChevronRight } from '@tabler/icons-vue';
import { ObSpace } from '../space';

// References:
// https://zagjs.com/components/react/pagination
// https://hds.hel.fi/components/pagination
// https://clayui.com/docs/components/pagination.html
// https://seek-oss.github.io/braid-design-system/components/Pagination

// TODO: render pages https://mui.com/material-ui/react-pagination/#pagination-ranges

// TODO: a11y

// TODO: support router links (see references for possible solutions)
// https://mui.com/material-ui/react-pagination/#router-integration

interface Props {
  total?: number;
  modelValue?: number;
  pageSize?: number;
}

const props = withDefaults(defineProps<Props>(), {
  total: 0,
  modelValue: 1,
  pageSize: 10,
});

const emit = defineEmits<{
  'update:modelValue': [value: number];
}>();

const pageSize = toRef(props, 'pageSize');

const { currentPage, isFirstPage, isLastPage, prev, next } = useOffsetPagination({
  total: computed(() => props.total),
  page: props.modelValue, // cannot use computed here and have to use watcher because of `useClamp` inside `useOffsetPagination` (see sources)
  pageSize: () => pageSize.value,
  onPageChange: ({ currentPage }) => {
    emit('update:modelValue', currentPage);
  },
});

watch(
  () => props.modelValue,
  (value) => {
    currentPage.value = value;
  },
);
</script>

<template>
  <ObSpace spacing="3" align-y="center">
    <button
      :disabled="isFirstPage"
      :class="$style.button"
      type="button"
      aria-label="Go to previous page"
      @click="prev()"
    >
      <IconCircleChevronLeft aria-hidden="true" />
    </button>
    <button
      :disabled="isLastPage"
      :class="$style.button"
      type="button"
      aria-label="Go to next page"
      @click="next()"
    >
      <IconCircleChevronRight aria-hidden="true" />
    </button>
  </ObSpace>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';

.button {
  @include shared.reset-button();
  display: inline-flex;
  vertical-align: middle;
  font-size: 24px;
  width: 1em;
  height: 1em;
  border-radius: 12px;
  line-height: 1em;
  color: #604f92; // TODO: token

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }

  &:disabled {
    cursor: not-allowed;
    color: #aeb3c4; // TODO: token
  }
}
</style>
