<script lang="ts" setup>
import {
  ref,
  computed,
  h,
  useSlots,
  useCssModule,
  cloneVNode,
  Transition,
  Teleport,
  watchEffect,
  watch,
  nextTick,
} from 'vue';
import type { VNode } from 'vue';
import { useVModel, onClickOutside } from '@vueuse/core';
import { useFloating, offset, flip, size, autoUpdate, shift } from '@floating-ui/vue';
import type { Middleware, Placement } from '@floating-ui/vue';
import { useFocusTrap, type UseFocusTrapOptions } from '@vueuse/integrations/useFocusTrap';
import type { AriaRole } from '../../shared/types';
import { useFocusZone, useZIndex, useFocusScope } from '../../composables';
import type { UseFocusZoneOptions } from '../../composables';
import { pxOrValue } from '../../utils';
import { widthMap, heightMap } from './config';
import type { FloatingSurfaceExpose } from './expose';

export interface FloatingSurfaceProps {
  active?: boolean;
  placement?: Placement;
  role?: AriaRole;
  width?: keyof typeof widthMap;
  height?: keyof typeof heightMap;
  maxHeight?: keyof Omit<typeof heightMap, 'auto'> | 'fit';
  maxWidth?: keyof Omit<typeof widthMap, 'auto'> | 'fit';
  minHeight?: keyof Omit<typeof heightMap, 'auto'>;
  minWidth?: keyof Omit<typeof widthMap, 'auto'>;
  focusZoneOptions?: UseFocusZoneOptions;
  focusTrapOptions?: UseFocusTrapOptions;
  flip?: boolean;
  offset?: number;
  padding?: number;
  variant?: 'dropdown' | 'panel';
}

const props = withDefaults(defineProps<FloatingSurfaceProps>(), {
  active: false,
  placement: 'bottom-start',
  role: 'none',
  width: undefined,
  height: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  minHeight: undefined,
  minWidth: undefined,
  focusZoneOptions: undefined,
  focusTrapOptions: undefined,
  flip: true,
  offset: 4,
  padding: 12,
  variant: 'panel',
});

const emit = defineEmits<{
  'update:active': [active: boolean];
  activated: [];
  deactivated: [];
}>();

const active = useVModel(props, 'active', emit, {
  passive: true,
});
const notActive = computed(() => !active.value);
const portalActive = ref(false);

watchEffect(() => {
  if (active.value) {
    portalActive.value = true;
  }
});

const hostRef = ref<HTMLElement | null>(null);
const floatingRef = ref<HTMLElement | null>(null);

defineExpose<FloatingSurfaceExpose>({
  $el: floatingRef,
});

function activate() {
  active.value = true;
  emit('update:active', active.value);
  emit('activated');
}

function deactivate() {
  active.value = false;
  emit('update:active', active.value);
  emit('deactivated');
}

function onHostKeyDown(event: KeyboardEvent) {
  if (event.defaultPrevented) {
    return;
  }
  if (!active.value && ['ArrowDown', 'ArrowUp', ' ', 'Enter'].includes(event.key)) {
    event.preventDefault();
    activate();
  }
}

function onHostClick(event: PointerEvent) {
  if (event.defaultPrevented || event.button !== 0) {
    return;
  }

  if (active.value) {
    deactivate();
    return;
  }

  activate();
}

function onFloatingKeyDown(event: KeyboardEvent) {
  if (event.defaultPrevented) {
    return;
  }
  if (event.key === 'Escape') {
    event.preventDefault();
    deactivate();
    nextTick(() => {
      hostRef.value?.focus();
    });
  }
}

const middleware = computed<Middleware[]>(() => {
  const result = [
    size({
      padding: props.padding,
      apply({ elements, availableWidth, availableHeight }) {
        if (props.maxHeight === 'fit') {
          elements.floating.style.maxHeight = pxOrValue(availableHeight);
        }
        if (props.maxWidth === 'fit') {
          elements.floating.style.maxWidth = pxOrValue(availableWidth);
        }
      },
    }),
    offset(() => props.offset),
  ];
  if (props.flip) {
    result.push(
      flip({
        padding: props.padding,
      }),
    );
  }
  result.push(
    shift({
      padding: props.padding,
    }),
  );
  return result;
});

const { floatingStyles, placement } = useFloating(hostRef, floatingRef, {
  placement: props.placement,
  middleware,
  whileElementsMounted: autoUpdate,
  open: portalActive,
  transform: false,
});

useFocusZone(floatingRef, { disabled: notActive, ...props.focusZoneOptions });

const { zIndex } = useZIndex({ active: portalActive });

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(floatingRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: false,
  fallbackFocus: () => {
    // TODO: it's needed to prevent error when FloatingSurface is empty. Seems more solid and simple solution, but need to check.
    return floatingRef.value as HTMLElement;
  },
  ...props.focusTrapOptions,
});

const composedFloatingStyles = computed(() => {
  const result: Partial<CSSStyleDeclaration> = {
    ...floatingStyles.value,
  };
  if (zIndex.value) {
    result.zIndex = zIndex.value.toString();
  }
  if (props.width && props.width !== 'auto') {
    result.width = widthMap[props.width];
  }
  if (props.maxWidth && props.maxWidth !== 'fit') {
    result.maxWidth = widthMap[props.maxWidth];
  }
  if (props.height && props.height !== 'auto') {
    result.height = heightMap[props.height];
  }
  if (props.maxHeight && props.maxHeight !== 'fit') {
    result.maxHeight = heightMap[props.maxHeight];
  }
  if (props.minWidth) {
    result.minWidth = widthMap[props.minWidth];
  }
  if (props.minHeight) {
    result.minHeight = heightMap[props.minHeight];
  }
  return result;
});

const { active: focusScopeActive } = useFocusScope(floatingRef);

onClickOutside(
  floatingRef,
  () => {
    // Ignore click outside for focus scope
    if (focusScopeActive.value) {
      return;
    }

    deactivate();
  },
  { ignore: [hostRef] },
);

watch(
  active,
  (value, oldValue) => {
    if (value) {
      nextTick(() => {
        if (!active.value) {
          return;
        }
        activateTrap();
      });
      return;
    }

    if (typeof oldValue === 'undefined') {
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

// Template
defineSlots<{
  default?: () => VNode;
  host?: (props: { active: boolean }) => VNode;
}>();

const slots = useSlots();
const style = useCssModule();
const basePlacement = computed(() => placement.value.split('-')[0]);

defineRender(() => {
  const [host] = slots.host?.({ active: active.value }) ?? [];
  return [
    host
      ? h(
          cloneVNode(host, {
            'aria-haspopup': 'true',
            'aria-expanded': active.value ? 'true' : undefined,
            tabIndex: '0',
            onClick: onHostClick,
            onKeydown: onHostKeyDown,
          }),
          {
            ref: hostRef,
          },
        )
      : null,
    portalActive.value
      ? h(Teleport, { to: 'body' }, [
          h(
            Transition,
            {
              enterFromClass: style.transitionEnterFrom,
              enterActiveClass: style.transitionEnterActive,
              leaveActiveClass: style.transitionLeaveActive,
              leaveToClass: style.transitionLeaveTo,
              appear: true,
              onAfterLeave: () => {
                portalActive.value = false;
              },
            },
            () =>
              active.value
                ? h(
                    'div',
                    {
                      ref: floatingRef,
                      class: [
                        style.root,
                        {
                          [style.placementTop]: basePlacement.value === 'top',
                          [style.placementLeft]: basePlacement.value === 'left',
                          [style.placementRight]: basePlacement.value === 'right',
                          [style.placementBottom]: basePlacement.value === 'bottom',
                          [style.variantDropdown]: props.variant === 'dropdown',
                          [style.variantPanel]: props.variant === 'panel',
                        },
                      ],
                      tabindex: '-1',
                      style: composedFloatingStyles.value,
                      role: props.role,
                      onKeydown: onFloatingKeyDown,
                    },
                    slots.default?.(),
                  )
                : null,
          ),
        ])
      : null,
  ];
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/elevation';

.root {
  box-sizing: border-box;
  background: colors.$white;
  border: 1px solid colors.$surface-6;
  color: colors.$primary;
  position: absolute;
  min-width: 192px;
  height: auto;
  width: auto;
  overflow: hidden;
}

.variantDropdown {
  @include elevation.shadow(2);
  border-radius: shared.$border-radius-s;
}

.variantPanel {
  @include elevation.shadow(3);
  border-radius: shared.$border-radius-m;
}

.transitionEnterFrom,
.transitionLeaveTo {
  opacity: 0;
  &.placementLeft {
    transform: translateX(-10px);
  }
  &.placementRight {
    transform: translateX(10px);
  }
  &.placementTop {
    transform: translateY(-10px);
  }
  &.placementBottom {
    transform: translateY(10px);
  }
}

.transitionEnterActive,
.transitionLeaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.15s;
}

.transitionEnterActive {
  transition-timing-function: ease-out;
}

.transitionLeaveActive {
  transition-timing-function: ease-in;
}
</style>
