<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';

.root {
  font-family: typography.$font-family-primary;
  font-size: 12px;
  line-height: 18px;
  color: colors.$status-danger;
}
</style>
