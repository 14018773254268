<script lang="ts" setup>
import type { DataTableColumnAlign } from './types';
import style from './ob-data-table.module.scss';

type Props = {
  align?: DataTableColumnAlign;
  monospace?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  align: 'start',
  monospace: false,
});
</script>

<template>
  <div
    :class="[
      style.cell,
      style.bodyCell,
      {
        [style.cellAlignCenter]: props.align === 'center',
        [style.cellAlignEnd]: props.align === 'end',
        [style.cellAlignStart]: props.align === 'start',
        [style.cellMonospace]: props.monospace,
      },
    ]"
  >
    <slot />
  </div>
</template>
