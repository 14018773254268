import { numberToStringWithoutExp } from './number-to-string-without-exp';

/**
 * Return fractional part of number
 */
export function getNumberDecimalsPart(value: number, precision?: number): string {
  const [, decimals = ''] = numberToStringWithoutExp(value).split('.');

  return precision ? decimals.substring(0, precision) : decimals;
}
