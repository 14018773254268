<script lang="ts" setup>
import type { Spacing } from '../../shared/types';

type Props = {
  vertical?: boolean;
  spacing?: Spacing;
};

const props = withDefaults(defineProps<Props>(), {
  vertical: false,
  spacing: undefined,
});
</script>

<template>
  <hr
    :class="[
      'ob-separator',
      $style.root,
      {
        [$style.vertical]: props.vertical,
        [$style.horizontal]: !props.vertical,
        [`_spacing-${props.spacing}`]: props.spacing,
      },
    ]"
  />
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/spacing';

.root {
  --spacing: 0;

  border: 0;
  background: colors.$surface-16;
  margin: 0;
  flex-shrink: 0;
}

.horizontal {
  display: block;
  height: 1px;
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}

.vertical {
  width: 1px;
  height: auto;
  align-self: stretch;
  margin-left: var(--spacing);
  margin-right: var(--spacing);
}
</style>

<style lang="scss">
@use 'sass:map';
@use '../../styles/spacing';

// TODO: find solution for css modules

.ob-separator {
  --spacing: 0;

  @each $spacing-key in map.keys(spacing.$spacers) {
    $spacing-value: map.get(spacing.$spacers, $spacing-key);

    &._spacing-#{$spacing-key} {
      --spacing: #{$spacing-value};
    }
  }
}
</style>
