import type { InjectionKey, MaybeRefOrGetter } from 'vue';
import type { AriaRole } from '../../../shared/types';

interface ActionListContainerContext {
  selectionMode?: MaybeRefOrGetter<'single' | 'multiple' | undefined>;
  listRole?: MaybeRefOrGetter<AriaRole | undefined>;
  itemRole?: MaybeRefOrGetter<AriaRole | undefined>;
  compact?: MaybeRefOrGetter<boolean | undefined>;
  onAfterSelect?: () => void;
}

// ActionListContainerContext is a way to define properties (default values) for child ActionList

export const ACTION_LIST_CONTAINER_CONTEXT: InjectionKey<ActionListContainerContext> = Symbol(
  __DEV__ ? 'ActionListContainer context' : '',
);
