<script lang="ts" setup>
import { ref } from 'vue';
import { useElementHover, useFocus } from '@vueuse/core';
import { useFocusVisible } from '../../composables';
import { ObPrimitiveCheckbox } from '../primitive-checkbox';

interface Props {
  disabled?: boolean;
  label?: string;
  selected?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  label: '',
  selected: false,
});

const rootRef = ref();

const focusVisible = useFocusVisible();
const hovered = useElementHover(rootRef);
const { focused } = useFocus(rootRef);
</script>

<template>
  <li
    ref="rootRef"
    class="ob-multiselect-option"
    :class="{ _selected: props.selected }"
    :aria-selected="props.selected"
    :aria-label="props.label"
    role="option"
    tabindex="-1"
  >
    <div class="ob-multiselect-option__checkbox">
      <ObPrimitiveCheckbox
        :checked="props.selected"
        :hovered="hovered"
        :disabled="props.disabled"
        :focused="focused && focusVisible"
      />
    </div>
    <div class="ob-multiselect-option__label">
      <slot>{{ props.label }}</slot>
    </div>
  </li>
</template>

<style lang="scss">
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.ob-multiselect-option {
  position: relative;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 12px;
  background: #fff;
  outline: none;
  font-family: typography.$font-family-primary;
  cursor: pointer;

  &__checkbox {
    display: flex;
    margin-right: 6px;
    padding-top: 1px;
  }

  &__label {
    font-size: 14px;
    line-height: 20px;
    color: colors.$primary;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }

  &._selected {
    background: #f8f7fe; // TODO: use token

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 3px;
      left: 0;
      top: 0;
      background: #907ff5; // TODO: use token
    }
  }

  &:hover,
  &:focus-visible {
    background: #f2f0fe; // TODO: use token
  }
}
</style>
