<script lang="ts" setup>
import { ref, computed, watch, provide, useId } from 'vue';
import { useVModel } from '@vueuse/core';
import { IconChevronDown } from '@tabler/icons-vue';
import { toString } from 'lodash-es';
import { ObFloatingSurface, type FloatingSurfaceExpose } from '../floating-surface';
import { useMenuKeyboardNavigation } from '../../composables';
import {
  ObActionList,
  ObActionListItem,
  ACTION_LIST_CONTAINER_CONTEXT,
} from '../../lab/components/action-list';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { getValueByKey, areValuesEqual } from '../../utils';
import { hasSlotContent } from '../../../ui-kit';

interface Props {
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: any;
  options: Array<unknown>;
  optionDisabled?: string | ((option: any) => boolean);
  optionLabel?: string | ((option: any) => string);
  optionValue?: string | ((option: any) => unknown);
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  trackValueBy?: string | ((value: unknown) => unknown);
}

interface Option {
  key: string;
  label: string;
  disabled: boolean;
  value: unknown;
  selected: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  invalid: false,
  modelValue: null,
  options: () => [],
  optionDisabled: undefined,
  optionLabel: undefined,
  optionValue: undefined,
  placeholder: '',
  size: 'm',
  trackValueBy: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: unknown];
}>();

const modelValue = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
  defaultValue: null,
});

const options = computed<Option[]>(() => {
  return props.options.map((option, index) => {
    const value = props.optionValue ? getValueByKey(option, props.optionValue) : option;

    return {
      key: toString((props.trackValueBy && getValueByKey(value, props.trackValueBy)) ?? index),
      label: toString(props.optionLabel ? getValueByKey(option, props.optionLabel) : option),
      disabled: !!(props.optionDisabled ? getValueByKey(option, props.optionDisabled) : false),
      value,
      selected: areValuesEqual(value, modelValue.value, props.trackValueBy),
    };
  });
});

let cachedSelectedOption: Option;

const selectedOption = computed<Option>(
  () => options.value.find(({ selected }) => selected) || cachedSelectedOption,
);

watch(
  selectedOption,
  (value) => {
    cachedSelectedOption = value;
  },
  { deep: true, immediate: true },
);

const open = ref(false);
const listId = useId();

// TODO: select the last item if open by ArrowUp if no selected item
// TODO: aria-haspopup="listbox" - how to set it in FloatingSurface?

provide(ACTION_LIST_CONTAINER_CONTEXT, {
  listRole: 'listbox',
  itemRole: 'option',
  onAfterSelect: () => {
    open.value = false;
  },
});

const hostRef = ref<HTMLElement | null>(null);
const containerRef = ref<FloatingSurfaceExpose | null>(null);

useMenuKeyboardNavigation(
  open,
  hostRef,
  computed(() => containerRef.value?.$el),
  () => {
    open.value = false;
    hostRef.value?.focus();
  },
);

function focusInStrategy(): HTMLElement | undefined {
  return containerRef.value?.$el?.querySelector('[aria-checked="true"]') || undefined;
}
</script>

<template>
  <ObFloatingSurface
    ref="containerRef"
    v-model:active="open"
    :focus-trap-options="{ returnFocusOnDeactivate: true }"
    :focus-zone-options="{ focusOutBehavior: 'wrap', focusInStrategy }"
    variant="dropdown"
  >
    <template #host>
      <button
        ref="hostRef"
        type="button"
        :class="[
          $style.root,
          {
            [$style.disabled]: props.disabled,
            [$style.invalid]: props.invalid,
            [$style.focused]: open,
            [$style.sizeS]: props.size === 's',
            [$style.sizeM]: props.size === 'm',
            [$style.sizeL]: props.size === 'l',
          },
        ]"
        :disabled="props.disabled"
        :aria-controls="listId"
        :aria-owns="listId"
        aria-autocomplete="list"
      >
        <span v-if="hasSlotContent($slots.prefix)" :class="$style.prefix">
          <slot name="prefix" />
        </span>
        <span :class="$style.value">
          <template v-if="selectedOption">{{ selectedOption.label }}</template>
          <span v-else-if="props.placeholder" :class="$style.placeholder">
            {{ props.placeholder }}
          </span>
        </span>
        <span :class="[$style.arrow, { [$style.arrowRotated]: open }]">
          <IconChevronDown aria-hidden="true" />
        </span>
      </button>
    </template>
    <ObActionList :id="listId" selection-mode="single">
      <ObActionListItem
        v-for="option in options"
        :key="option.key"
        :disabled="option.disabled"
        :selected="option.selected"
        @select="modelValue = option.value"
      >
        {{ option.label }}
      </ObActionListItem>
    </ObActionList>
  </ObFloatingSurface>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  display: flex;
  text-align: left;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  border-radius: shared.$border-radius-s;
  min-height: 44px;
  display: flex;
  box-sizing: border-box;
  text-align: left;

  &::after {
    @include shared.coverer();
    content: '';
    border-radius: inherit;
    border: 1px solid colors.$surface-16;
    pointer-events: none;
    box-sizing: border-box;
  }
}

.value {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  color: colors.$surface-40;
}

.arrow {
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}

.root:focus-visible,
.focused {
  &::after {
    border-color: #907ff5; // TODO: use token
  }
}

.invalid {
  &::after {
    border-color: colors.$status-danger;
  }
}

.disabled {
  color: colors.$surface-40;
  background-color: colors.$surface-4;
  cursor: not-allowed;
}

.sizeS {
  min-height: 32px;
}
.sizeM {
  min-height: 44px;
}
.sizeL {
  min-height: 56px;
}

.prefix {
  color: colors.$surface-40;
  margin-right: 4px;
}
</style>
