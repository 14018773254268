import type { InjectionKey, ComputedRef } from 'vue';

interface ObTabsContext {
  setValue: (value: string) => void;
  isValueSelected: (value: string) => boolean;
  id: string;
  variant: ComputedRef<'primary' | 'secondary'>;
  selectionFollowFocus: ComputedRef<boolean>;
}

export const OB_TABS_CONTEXT: InjectionKey<ObTabsContext> = Symbol(__DEV__ ? 'Tabs context' : '');
