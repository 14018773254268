import { isUndefined } from 'lodash-es';
import type { Path } from '../shared/types';
import { getValueByKey } from './get-value-by-key';

export function areValuesEqual<V = unknown>(
  value1: V,
  value2: V,
  trackBy?: (V extends unknown ? string : Path<V>) | ((value: V) => unknown),
) {
  const trackedValue1 = trackBy ? getValueByKey(value1, trackBy) : value1;
  const trackedValue2 = trackBy ? getValueByKey(value2, trackBy) : value2;

  if (isUndefined(trackedValue1) || isUndefined(trackedValue2)) {
    return false;
  }

  return trackedValue1 === trackedValue2;
}
