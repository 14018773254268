<script lang="ts" setup>
interface Props {
  for?: string;
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  for: '',
  id: '',
});
</script>

<template>
  <label :id="props.id" :for="props.for" :class="$style.root">
    <slot />
  </label>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';

.root {
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 20px;
  color: colors.$primary;
}
</style>
