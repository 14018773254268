<script lang="ts" setup>
interface Props {
  items: string[];
  activeIndex?: number;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  activeIndex: 0,
});
</script>

<template>
  <div :class="$style.root">
    <div
      v-for="(item, i) in props.items"
      :key="i"
      :class="[
        $style.item,
        {
          [$style.itemActive]: props.activeIndex === i,
          [$style.itemCompleted]: props.activeIndex > i,
        },
      ]"
    >
      <div :class="$style.itemIcon" />
      <div :class="$style.itemLabel">{{ item }}</div>
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/typography';

.root {
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-family: typography.$font-family-primary;
}

.item {
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  text-align: center;

  &:not(:first-child)::before,
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    z-index: 5;
    height: 2px;
    background: #d7d9e2; // TODO: use design token
    top: 11px;
    width: 50%;
  }

  &:not(:first-child)::before {
    left: 0;
  }

  &:not(:last-child)::after {
    right: 0;
  }
}

.itemIcon {
  position: relative;
  z-index: 10;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 8px solid #9aa0b6; // TODO: use design token
  background: #fff;
  margin: 0 auto 12px;
}

.itemActive,
.itemCompleted {
  > .itemIcon {
    border-color: #907ff5; // TODO: use design token
    box-shadow: 0px 0px 0px 4px #f4ebff; // TODO: use design token
  }
}

.itemActive {
  &:not(:first-child)::before {
    background: #907ff5; // TODO: use design token
  }
}

.itemCompleted {
  &:not(:first-child)::before,
  &:not(:last-child)::after {
    background: #907ff5; // TODO: use design token
  }
}
</style>
