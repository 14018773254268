<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useVModel, useElementSize, unrefElement } from '@vueuse/core';
import { IconLoader2, IconX } from '@tabler/icons-vue';
import { areValuesEqual, pxOrValue } from '../../utils';
import { useChildrenFocusControl, useOptionsList, useDropdown } from '../../composables';
import type { UseOptionsListOption } from '../../composables';
import { Keys } from '../../shared/enums';
import { ObPrimitiveInput } from '../primitive-input';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { ObTag } from '../tag';
import { ObScrollableContainer } from '../scrollable-container';
import ObMultiSelectOption from './ob-multi-select-option.vue';

// TODO: Tags focus management
// TODO: Disabled state
// TODO: Disabled option
// TODO: aria-label or aria-labeledby for <input> and listbox
// TODO: wait floatingPositioned before start transition
// TODO: virtual scroll
// TODO: when options count > options.limit show text about it (e.g. "100 of 1000 options are visible. Type to find options")

interface Props {
  clearable?: boolean;
  disabled?: boolean;
  externalSearch?: boolean;
  id?: string;
  invalid?: boolean;
  loading?: boolean;
  modelValue?: unknown[];
  options: Array<unknown>;
  optionsLimit?: number;
  optionDisabled?: string | ((option: any) => boolean);
  optionLabel?: string | ((option: any) => string);
  optionValue?: string | ((option: any) => unknown);
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  tagsLimit?: number;
  trackValueBy?: string | ((value: unknown) => unknown);
  onBeforeSelect?: (value: unknown) => boolean | void;
}

const props = withDefaults(defineProps<Props>(), {
  clearable: true,
  disabled: false,
  externalSearch: false,
  id: undefined,
  invalid: false,
  loading: false,
  modelValue: () => [],
  options: () => [],
  optionsLimit: 300,
  optionDisabled: undefined,
  optionLabel: undefined,
  optionValue: undefined,
  placeholder: '',
  size: 'm',
  tagsLimit: 30,
  trackValueBy: undefined,
  onBeforeSelect: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: unknown];
  focus: [];
  blur: [];
  search: [value: string];
}>();

const modelValue = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
  defaultValue: [],
});

const {
  containerRef,
  dropdownRef,
  focused,
  active: dropdownActive,
  visible: dropdownVisible,
  styles: dropdownStyles,
  onPositioned: onDropdownPositioned,
  placement: dropdownPlacement,
  id: dropdownId,
  moveFocusOut,
} = useDropdown();

watch(focused, (value) => {
  if (value) {
    emit('focus');
    return;
  }
  emit('blur');
});

const { focusFirst, focusLast, focusNext, focusPrevious } = useChildrenFocusControl({
  root: dropdownRef,
});

const searchText = ref('');

const { options, selectedOptions } = useOptionsList({
  items: computed(() => props.options),
  searchText: computed(() => (!props.externalSearch ? searchText.value : '')),
  selectedItems: modelValue,
  optionLabel: props.optionLabel,
  optionValue: props.optionValue,
  optionsLimit: props.optionsLimit,
  trackValueBy: props.trackValueBy,
});

const tags = computed(() => selectedOptions.value.slice(0, Math.max(0, props.tagsLimit)));

const moreSelectedOptionsCount = computed(() => selectedOptions.value.length - tags.value.length);

const inputRef = ref();
const searchSizerRef = ref();

const placeholder = computed(() => {
  if (modelValue.value.length > 0) {
    return '';
  }

  return props.placeholder;
});

const { width: searchSizerWidth } = useElementSize(searchSizerRef);

const inputWidth = computed(() => {
  return pxOrValue(searchSizerWidth.value || 40);
});

function focusInput() {
  unrefElement(inputRef)?.focus();
}

function removeSelectedValue(value: unknown) {
  modelValue.value = modelValue.value.filter(
    (item: unknown) => !areValuesEqual(item, value, props.trackValueBy),
  );
}

function toggleValueSelection(value: unknown): void {
  if (modelValue.value.some((item: unknown) => areValuesEqual(item, value, props.trackValueBy))) {
    removeSelectedValue(value);
    return;
  }

  searchText.value = '';

  if (typeof props.onBeforeSelect === 'function') {
    if (props.onBeforeSelect(value) === false) {
      return;
    }
  }

  modelValue.value.push(value);
}

function clear() {
  modelValue.value = [];
  focusInput();
}

watch(focused, (value) => {
  if (value || !document.hasFocus()) {
    return;
  }

  dropdownActive.value = false;
});

function onOptionClick(event: MouseEvent, option: UseOptionsListOption) {
  toggleValueSelection(option.value);
  focusInput();
}

function onOptionKeydown(event: KeyboardEvent, option: UseOptionsListOption) {
  if (event.key !== Keys.Enter) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  toggleValueSelection(option.value);
}

function onSearch() {
  emit('search', searchText.value);
}

function onInputKeydown(event: KeyboardEvent) {
  const editingKeys: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

  switch (event.key) {
    case Keys.Escape: {
      event.stopPropagation();
      event.preventDefault();
      dropdownActive.value = false;
      break;
    }
    case Keys.ArrowUp:
    case Keys.ArrowDown: {
      event.preventDefault();
      event.stopPropagation();
      dropdownActive.value = true;

      onDropdownPositioned(() => {
        if (event.key === Keys.ArrowUp) {
          focusLast();
          return;
        }

        focusFirst();
      });

      break;
    }
    default:
      if (
        !dropdownActive.value &&
        !event.defaultPrevented &&
        (event.key.length === 1 || editingKeys.includes(event.key))
      ) {
        dropdownActive.value = true;
      }
      break;
  }
}

function onDropdownKeydown(event: KeyboardEvent) {
  const editingKeys: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

  switch (event.key) {
    case Keys.Escape: {
      event.stopPropagation();
      event.preventDefault();
      focusInput();
      dropdownActive.value = false;

      break;
    }
    case Keys.ArrowUp:
    case Keys.ArrowDown: {
      event.preventDefault();
      event.stopPropagation();

      if (event.key === Keys.ArrowUp) {
        focusPrevious();
        return;
      }

      focusNext();

      break;
    }
    case Keys.Home:
    case Keys.PageUp: {
      event.preventDefault();
      event.stopPropagation();
      focusFirst();
      break;
    }
    case Keys.End:
    case Keys.PageDown: {
      event.preventDefault();
      event.stopPropagation();
      focusLast();
      break;
    }
    case Keys.Tab: {
      // Try to move focus from dropdown to the next/prev focusable element.
      // If nothing found (multi select is the last or first element on the page
      // return focus to the multi select element.
      event.preventDefault();
      event.stopPropagation();

      if (!moveFocusOut(event.shiftKey)) {
        focusInput();
      }

      break;
    }
    default: {
      if (!event.defaultPrevented && (event.key.length === 1 || editingKeys.includes(event.key))) {
        focusInput();
      }

      break;
    }
  }
}

// Keep focus when clicking on wrapper
function onWrapperMouseDown(event: MouseEvent) {
  if (event.target === unrefElement(inputRef)) {
    return;
  }

  event.preventDefault();
  focusInput();
}

function onWrapperClick(event: MouseEvent) {
  event.preventDefault();
  dropdownActive.value = true;
}
</script>

<template>
  <ObPrimitiveInput
    ref="containerRef"
    :size="props.size"
    :focused="focused"
    @mousedown="onWrapperMouseDown"
    @click="onWrapperClick"
  >
    <div :class="$style.sizer">
      <div ref="searchSizerRef" :class="$style.sizerText">
        >
        {{ searchText || placeholder }}
      </div>
    </div>
    <div :class="$style.container">
      <div :class="$style.main">
        <template v-if="modelValue.length">
          <slot name="value" v-bind="{ value: modelValue, selectedOptions }">
            <ObTag
              v-for="tag in tags"
              :key="tag.key"
              removable
              :remover-attributes="{ tabindex: '-1' }"
              @remove="removeSelectedValue(tag.value)"
              >{{ tag.label }}</ObTag
            >
          </slot>
          <span v-if="moreSelectedOptionsCount > 0">
            <template v-if="props.tagsLimit < 1"
              >{{ modelValue.length }}
              {{ modelValue.length > 1 ? 'items' : 'item' }} selected</template
            >
            <template v-else>+{{ moreSelectedOptionsCount }} </template>
          </span>
        </template>
        <input
          :id="props.id"
          ref="inputRef"
          v-model="searchText"
          type="text"
          :class="$style.input"
          :style="{
            width: inputWidth,
          }"
          aria-autocomplete="list"
          :aria-controls="dropdownId"
          :aria-expanded="dropdownActive"
          aria-haspopup="listbox"
          :aria-owns="dropdownId"
          autocomplete="off"
          :disabled="disabled"
          :placeholder="placeholder"
          role="combobox"
          @keydown="onInputKeydown"
          @input="onSearch"
        />
      </div>
      <button
        v-if="props.clearable && modelValue.length"
        type="button"
        class="ob-multi-select__cleaner"
        aria-label="Clear"
        @click="clear()"
      >
        <IconX aria-hidden="true" />
      </button>
    </div>
  </ObPrimitiveInput>
  <Teleport to="body">
    <Transition
      name="_transition"
      @before-enter="dropdownVisible = true"
      @after-leave="dropdownVisible = false"
    >
      <ObScrollableContainer
        v-if="dropdownActive"
        ref="dropdownRef"
        tabindex="-1"
        :style="dropdownStyles"
        class="ob-multi-select-dropdown"
        :class="{ [`_placement-${dropdownPlacement}`]: dropdownPlacement }"
        light
        @keydown="onDropdownKeydown"
      >
        <div v-if="props.loading" class="ob-multi-select-loader">
          <div class="ob-multi-select-loader__icon">
            <IconLoader2 aria-hidden="true" />
          </div>
        </div>
        <ul
          v-else-if="options.length"
          :id="dropdownId"
          aria-multi-selectable="true"
          role="listbox"
          class="ob-multi-select-options"
        >
          <ObMultiSelectOption
            v-for="option in options"
            :key="option.key"
            :label="option.label"
            :selected="option.selected"
            @click="onOptionClick($event, option)"
            @keypress="onOptionKeydown($event, option)"
          />
        </ul>
        <div v-else class="ob-multi-select-message">
          <slot name="noOptions">No options found</slot>
        </div>
        <div />
        <!-- This div is needed to focus last element -->
      </ObScrollableContainer>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.input {
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  border-radius: inherit;
  background: none;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  border: none;
  padding: 0;

  &::placeholder {
    color: colors.$surface-40;
  }
}

.sizer {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  visibility: hidden;
}

.sizerText {
  max-width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
}

.cleaner {
  @include shared.reset-button();
  display: flex;
  color: inherit;
  font-size: 24px;
  width: 1em;
  height: 1em;
  cursor: pointer;
  pointer-events: auto;
  color: colors.$surface-40;
  margin-left: 12px;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}
</style>

<style lang="scss">
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/elevation';
@use '../../styles/typography';

.ob-multi-select {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 12px;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-width: 0;
  }

  &__utils {
    padding-left: 12px;
  }

  &__input {
    text-align: inherit;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-transform: inherit;
    border-radius: inherit;
    background: none;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    caret-color: currentColor;
    outline: none;
    appearance: none;
    word-break: keep-all;
    border: none;
    padding: 0;

    &::placeholder {
      color: colors.$surface-40;
    }
  }

  &__sizer {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    visibility: hidden;

    &-text {
      max-width: 100%;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }

  &__cleaner {
    @include shared.reset-button();
    display: flex;
    color: inherit;
    font-size: 24px;
    width: 1em;
    height: 1em;
    cursor: pointer;
    pointer-events: auto;
    color: colors.$surface-40;

    &:focus-visible {
      outline: 1px solid colors.$hyperlink;
      outline-offset: -1px;
    }
  }
}

.ob-multi-select-dropdown {
  @include elevation.shadow(3);
  border-radius: shared.$border-radius-s;
  background: colors.$white;
  border: 1px solid colors.$surface-6;

  &._transition-enter-from,
  &._transition-leave-to {
    opacity: 0;

    &._placement-left {
      transform: translateX(-10px);
    }

    &._placement-right {
      transform: translateX(10px);
    }

    &._placement-top {
      transform: translateY(-10px);
    }

    &._placement-bottom {
      transform: translateY(10px);
    }
  }

  &._transition-enter-active,
  &._transition-leave-active {
    transition-property: opacity, transform;
    transition-duration: 0.2s;
  }

  &._transition-enter-active {
    transition-timing-function: ease-out;
  }

  &._transition-leave-active {
    transition-timing-function: ease-in;
  }
}

.ob-multi-select-options {
  margin: 0;
  padding: 0;
}

.ob-multi-select-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  text-align: center;
  color: #907ff5;
  font-size: 24px;

  &__icon {
    display: flex;
    animation: spinner 1s linear infinite;
  }
}

.ob-multi-select-message {
  padding: 12px;
  font-size: 14px;
  line-height: 24px;
  color: colors.$primary-80;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
