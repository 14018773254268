import { ref, computed, unref, watch, onUnmounted, useId } from 'vue';
import type { ComputedRef, Ref, MaybeRef } from 'vue';
import { useArrayFindIndex } from '@vueuse/core';

type StackItem<T = unknown> = { id: string; payload: T };
type Stack<T = unknown> = Ref<StackItem<T>[]>;

interface UseStackReturn<T = unknown> {
  stack: Stack<T>;
  add: () => void;
  remove: () => void;
  index: ComputedRef<number>;
  first: ComputedRef<boolean>;
  last: ComputedRef<boolean>;
  exists: ComputedRef<boolean>;
}

interface UseStackOptions<T = unknown> {
  payload?: MaybeRef<T>;
  active?: MaybeRef<boolean>;
}

const stacks = new Map<symbol | string, Stack>();

function remove(stackName: symbol | string, id: string): void {
  const stack = stacks.get(stackName);

  if (!stack) {
    return;
  }

  stack.value = stack.value.filter((item) => item.id !== id);
}

function add(stackName: symbol | string, id: string, payload?: unknown): void {
  const stack = stacks.get(stackName);

  if (!stack) {
    return;
  }

  remove(stackName, id);

  stack.value.push({ id, payload });
}

export function useStack<T = unknown>(
  name: string | symbol,
  options?: UseStackOptions,
): UseStackReturn<T> {
  const id = useId() as string;

  let stack: Stack<T>;

  if (!stacks.get(name)) {
    stack = ref([]);
    stacks.set(name, stack);
  } else {
    stack = stacks.get(name) as Stack<T>;
  }

  const index = useArrayFindIndex(stack, (item) => item.id === id);
  const exists = computed(() => index.value > -1);
  const first = computed(() => index.value === 0);
  const last = computed(() => exists.value && index.value === stack.value.length - 1);

  onUnmounted(() => remove(name, id));

  watch(
    () => unref(options?.active),
    (val, oldVal) => {
      if (val === oldVal) {
        return;
      }

      if (val) {
        add(name, id, options?.payload);
        return;
      }

      remove(name, id);
    },
    { immediate: true },
  );

  return {
    stack,
    add: () => add(name, id, options?.payload),
    remove: () => remove(name, id),
    index,
    exists,
    first,
    last,
  };
}
