<script lang="ts" setup>
import { computed } from 'vue';
import { injectStrict } from '../../utils';
import { OB_TABS_CONTEXT } from './shared';

interface Props {
  value: string;
}

const props = withDefaults(defineProps<Props>(), {});

const { isValueSelected, id } = injectStrict(
  OB_TABS_CONTEXT,
  undefined,
  '<ObTabsPanel /> must be a child of <ObTabs /> component.',
);

const selected = computed(() => isValueSelected(props.value));
</script>

<template>
  <div
    v-if="selected"
    :id="`${id}-panel-${value}`"
    role="tabpanel"
    :aria-labelledby="`${id}-tab-${value}`"
    :tabIndex="selected ? 0 : -1"
  >
    <slot />
  </div>
</template>
