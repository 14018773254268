<script lang="ts" setup>
import { computed } from 'vue';
import { isNil } from 'lodash-es';
import { ObRatioContainer } from '../ratio-container';
import { pxOrValue } from '../../utils';
import placeholder from './placeholder.svg';

interface Props {
  ratio?: number;
  source?: string;
  width?: string | number;
  height?: string | number;
}

const props = withDefaults(defineProps<Props>(), {
  ratio: undefined,
  source: undefined,
  width: '100%',
  height: '100%',
});

const width = computed<string>(() => (!isNil(props.width) ? pxOrValue(props.width) : ''));

const height = computed<string>(() => {
  if (props.ratio) {
    return '';
  }

  return !isNil(props.height) ? pxOrValue(props.height) : '';
});

const source = computed<string>(() => props.source ?? placeholder);
</script>

<template>
  <div :class="$style.root" :style="{ width, height }">
    <ObRatioContainer v-if="ratio" :ratio="props.ratio">
      <div :class="$style.content">
        <img :src="source" alt="" />
      </div>
    </ObRatioContainer>
    <div v-else :class="$style.content">
      <img :src="source" alt="" />
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/shared';

.root {
  position: relative;
  background: #e3e4eb; // TODO: use token
}

.content {
  @include shared.coverer();
  padding: 12px;
  box-sizing: border-box;
  pointer-events: none;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
  }
}
</style>
