<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useElementSize, useFocusWithin } from '@vueuse/core';
import { pxOrValue } from '../../utils';

interface Props {
  filler?: string;
  placeholder?: string;
  postfix?: string;
  prefix?: string;
  value?: string;
}

const props = withDefaults(defineProps<Props>(), {
  filler: '',
  placeholder: '',
  postfix: '',
  prefix: '',
  value: '',
});
const rootRef = ref();

const { focused } = useFocusWithin(rootRef);

const fillerText = computed(() => {
  if (!props.filler) {
    return '';
  }
  return props.filler.slice(props.value.length);
});

const prefixRef = ref<HTMLElement>();
const { width: prefixWidth } = useElementSize(
  prefixRef,
  { width: 0, height: 0 },
  { box: 'border-box' },
);

const style = computed(() => ({
  '--input-border-left': pxOrValue(prefixWidth.value),
}));
</script>

<template>
  <div ref="rootRef" :class="$style.root" :style="style">
    <slot>
      <input type="text" />
    </slot>
    <div :class="$style.decoration">
      <div v-if="props.prefix && (focused || value)" ref="prefixRef" :class="$style.prefix">
        {{ props.prefix }}
      </div>
      <div
        v-if="props.placeholder && !value && (!focused || !props.filler)"
        :class="$style.placeholder"
      >
        {{ props.placeholder }}
      </div>
      <div :class="$style.ghost">{{ value }}</div>
      <div v-if="fillerText && focused" :class="$style.filler">{{ fillerText }}</div>
      <div v-if="props.postfix && (focused || value)" :class="$style.postfix">
        {{ props.postfix }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';
@use '../../styles/iconography';

.root {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: 100%;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    margin: 0;
    padding: 0;
    text-align: inherit;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-transform: inherit;
    border-radius: inherit;
    background: none;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    caret-color: currentColor;
    outline: none;
    appearance: none;
    word-break: keep-all;
    border-left: var(--input-border-left) solid transparent;
    border-right: var(--input-border-right) solid transparent;

    &:disabled {
      // Safari 🤬
      -webkit-text-fill-color: colors.$surface-40;
      opacity: 1;
    }
  }
}

.prefix,
.postfix {
  white-space: pre;
}

.ghost {
  visibility: hidden;
  white-space: pre;
  text-overflow: clip;
}

.placeholder,
.filler {
  color: colors.$surface-40;
  white-space: pre;
}

.decoration {
  display: inline-flex;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
</style>
