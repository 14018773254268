<script lang="ts" setup>
interface Props {
  duration?: number | { enter: number; leave: number };
}

const props = withDefaults(defineProps<Props>(), {
  duration: 200,
});

// https://easings.net/

function easeOutQuad(x: number): number {
  return 1 - (1 - x) * (1 - x);
}

function easeInCirc(x: number): number {
  return 1 - Math.sqrt(1 - Math.pow(x, 2));
}

let currentHeight = 0;
let currentAnimationId: ReturnType<typeof requestAnimationFrame>;

interface AnimationOptions {
  element: Element;
  collapse: boolean;
  duration: number;
  onComplete: () => void;
}

function animateHeight(options: AnimationOptions) {
  cancelAnimationFrame(currentAnimationId);

  const element = options.element as HTMLElement;

  const height = element.scrollHeight;

  element.style.overflow = 'hidden';

  if (options.collapse) {
    currentHeight = element.offsetHeight;
  } else {
    element.style.height = `${currentHeight}px`;
  }

  if (!options.collapse && currentHeight === height) {
    return;
  }
  if (options.collapse && currentHeight === 0) {
    return;
  }

  const initialValue = currentHeight;
  const duration =
    (options.collapse ? currentHeight / height : 1 - currentHeight / height) * options.duration;

  let startTime: number;

  function animate(timestamp: number) {
    if (!startTime) {
      startTime = timestamp;
    }

    const runtime = timestamp - startTime;
    const relativeProgress = Math.min(1, runtime / duration);
    const easedProgress = options.collapse
      ? easeInCirc(relativeProgress)
      : easeOutQuad(relativeProgress);

    currentHeight = options.collapse
      ? currentHeight * (1 - Math.min(easedProgress, 1))
      : initialValue + (height - initialValue) * Math.min(easedProgress, 1);

    element.style.height = `${currentHeight}px`;

    if (runtime <= duration) {
      currentAnimationId = requestAnimationFrame(animate);
    } else {
      element.style.overflow = '';
      element.style.height = '';
      options.onComplete();
    }
  }

  currentAnimationId = requestAnimationFrame(animate);
}

function onEnter(element: Element, done: () => void) {
  animateHeight({
    element,
    collapse: false,
    duration: typeof props.duration === 'number' ? props.duration : props.duration.enter,
    onComplete: done,
  });
}

function onLeave(element: Element, done: () => void) {
  animateHeight({
    element,
    collapse: true,
    duration: typeof props.duration === 'number' ? props.duration : props.duration.leave,
    onComplete: done,
  });
}
</script>

<template>
  <Transition :css="false" @enter="onEnter" @leave="onLeave">
    <slot />
  </Transition>
</template>
