<script lang="ts" setup>
import { ref, computed, watchEffect } from 'vue';
import { colord } from 'colord';
import { IconUser } from '@tabler/icons-vue';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { stringToColor } from '../../utils';

interface Props {
  color?: string;
  name?: string;
  size?: SizeS | SizeM | SizeL;
  source?: string;
  textColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  color: undefined,
  name: undefined,
  size: 'm',
  source: undefined,
  textColor: undefined,
});

const imageLoaded = ref(false);

watchEffect(() => {
  if (!props.source) {
    imageLoaded.value = false;
    return;
  }

  const image = new window.Image();

  image.onload = () => {
    imageLoaded.value = true;
  };

  image.onerror = () => {
    imageLoaded.value = false;
  };

  image.src = props.source;
});

const initials = computed(() => {
  if (!props.name) {
    return '';
  }

  const [firstName, lastName] = props.name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  } else {
    return firstName.charAt(0);
  }
});

const style = computed(() => {
  if (!props.name) {
    return {};
  }

  const backgroundColor = props.color || (props.name ? stringToColor(props.name) : undefined);

  return {
    color:
      props.textColor ||
      (backgroundColor
        ? colord(backgroundColor).brightness() <= 0.4
          ? '#fff'
          : '#000'
        : undefined),
    backgroundColor,
  };
});
</script>

<template>
  <div class="ob-avatar" :class="{ [`_size-${props.size}`]: props.size }" :style="style">
    <div v-if="imageLoaded" class="ob-avatar__image">
      <img :src="props.source" :alt="props.name" />
    </div>
    <div v-else-if="props.name" class="ob-avatar__text">{{ initials }}</div>
    <div v-else class="ob-avatar__icon">
      <IconUser role="img">
        <title>Avatar image placeholder</title>
      </IconUser>
    </div>
  </div>
</template>

<style lang="scss">
@use '../../styles/typography';
@use '../../styles/colors';

.ob-avatar {
  $root: &;

  display: inline-flex;
  vertical-align: middle;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: colors.$surface-16;
  color: colors.$primary-80;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 600;

  &__image {
    display: flex;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__icon {
    display: flex;
    font-size: 24px;
  }

  &._size {
    &-s {
      width: 32px;
      height: 32px;
      font-size: 12px;

      #{$root}__icon {
        font-size: 16px;
      }
    }

    &-m {
      width: 48px;
      height: 48px;
      font-size: 16px;

      #{$root}__icon {
        font-size: 24px;
      }
    }

    &-l {
      width: 96px;
      height: 96px;
      font-size: 24px;

      #{$root}__icon {
        font-size: 48px;
      }
    }
  }
}
</style>
