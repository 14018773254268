<script lang="ts" setup>
import { mergeProps, computed, useSlots, h } from 'vue';
import type { AnchorTargetAttribute, SizeM, SizeL } from '../../shared/types';
import { validateAsChild } from '../../utils';

interface Props {
  asChild?: boolean;
  disabled?: boolean;
  size?: SizeM | SizeL;
  tabIndex?: string;
  // only for a element type
  href?: string;
  rel?: string;
  target?: AnchorTargetAttribute;
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  disabled: false,
  size: 'm',
  tabIndex: undefined,
  href: undefined,
  rel: undefined,
  target: undefined,
});

const slots = useSlots();

const attrs = computed(() =>
  mergeProps(
    {
      ...props,
      tabindex: props.disabled ? undefined : props.tabIndex,
      href: props.disabled ? undefined : props.href,
    },
    {
      class: [
        'ob-link',
        {
          _disabled: props.disabled,
          [`_size-${props.size}`]: props.size,
        },
      ],
    },
  ),
);

defineRender(() => {
  const children = slots.default?.();
  if (props.asChild) {
    if (!children || !validateAsChild(children)) {
      return null;
    }

    return h(children[0], attrs.value);
  }
  return h('a', attrs.value, children);
});
</script>

<style lang="scss">
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.ob-link {
  @include shared.reset-button();
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  font-style: inherit;
  text-transform: inherit;
  font-family: typography.$font-family-primary;
  font-weight: 400;
  letter-spacing: 0.025em;
  color: colors.$hyperlink;
  text-decoration: none;
  touch-action: manipulation;
  border-radius: shared.$border-radius-s;
  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: 2px;
  }

  &:disabled,
  &._disabled {
    cursor: not-allowed;
    color: colors.$hyperlink-40;
  }

  &:hover:enabled,
  &:hover:not(._disabled) {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.1em;
  }

  &,
  &._size-m {
    font-size: 14px; // TODO: use typography settings
    line-height: 20px;
  }

  &._size-l {
    font-size: 16px; // TODO: use typography settings
    line-height: 22px;
  }
}
</style>
