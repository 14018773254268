<script lang="ts" setup>
import { computed } from 'vue';
import type { Elevation } from '../../shared/types';

// TODO: support padding from props? https://polaris.shopify.com/components/layout-and-structure/alpha-card

interface ObPaneProps {
  elevation?: Elevation;
  selected?: boolean;
  hoverable?: boolean;
}

const props = withDefaults(defineProps<ObPaneProps>(), {
  elevation: '0',
  selected: false,
  hoverable: false,
});

const classes = computed(() => ({
  [`_elevation-${props.elevation}`]: props.elevation,
  _selected: props.selected,
  _hoverable: props.hoverable,
}));
</script>

<template>
  <div class="ob-pane" :class="classes">
    <slot />
  </div>
</template>

<style lang="scss">
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/elevation';

.ob-pane {
  border-radius: shared.$border-radius-m;
  background: colors.$white;
  border: 1px solid colors.$surface-6;
  transition: box-shadow 0.1s ease;

  &._elevation {
    &-1 {
      @include elevation.shadow(1);
    }

    &-2 {
      @include elevation.shadow(2);
    }

    &-3 {
      @include elevation.shadow(3);
    }
  }

  &._selected {
    border-color: #907ff5; // TODO: use token
  }

  &._hoverable {
    cursor: pointer;
  }

  &._hoverable:hover._elevation {
    &-0 {
      @include elevation.shadow(1);
    }

    &-1 {
      @include elevation.shadow(2);
    }

    &-2 {
      @include elevation.shadow(3);
    }
  }
}
</style>
