<script lang="ts" setup>
import { ObSpace } from '../space';
import { ObTooltip } from '../tooltip';
import ObLegendItem from './ob-legend-item.vue';

// TODO: consider different API with separated items and colors
// It can makes sense when we apply colors from array automatically
// https://www.tremor.so/docs/components/legend

interface Props {
  items: Array<{ color: string; label: string; description?: string }>;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
});
</script>

<template>
  <ObSpace spacing="4">
    <div v-for="(item, i) in props.items" :key="i">
      <slot name="item" v-bind="{ item }">
        <ObTooltip :disabled="!item.description">
          <template #host="{ hostProps }">
            <ObLegendItem :label="item.label" :color="item.color" v-bind="hostProps" />
          </template>
          {{ item.description }}
        </ObTooltip>
      </slot>
    </div>
  </ObSpace>
</template>
