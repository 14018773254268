<script lang="ts" setup>
import { ref, computed, provide, useSlots, h, cloneVNode } from 'vue';
import { useVModel } from '@vueuse/core';
import type { AriaRole } from '../../../shared/types';
import { useMenuKeyboardNavigation } from '../../../composables';
import { ObFloatingSurface } from '../../../components';
import type { FloatingSurfaceExpose, FloatingSurfaceProps } from '../../../components';
import { ACTION_LIST_CONTAINER_CONTEXT } from '../action-list';

type Props = {
  open?: boolean;
  role?: AriaRole;
  selectionMode?: 'single' | 'multiple';
  onSelect?: () => void;
  onAfterSelect?: () => void;
} & Pick<
  FloatingSurfaceProps,
  | 'width'
  | 'height'
  | 'maxHeight'
  | 'maxWidth'
  | 'minHeight'
  | 'minWidth'
  | 'focusZoneOptions'
  | 'focusTrapOptions'
>;

const props = withDefaults(defineProps<Props>(), {
  open: false,
  role: undefined,
  selectionMode: undefined,
  onSelect: undefined,
  onAfterSelect: undefined,
});

const emit = defineEmits<{
  'update:open': [open: boolean];
}>();

const open = useVModel(props, 'open', emit, {
  passive: true,
});
const hostRef = ref<HTMLElement | null>(null);
const containerRef = ref<FloatingSurfaceExpose | null>(null);

useMenuKeyboardNavigation(
  open,
  hostRef,
  computed(() => containerRef.value?.$el),
  () => {
    open.value = false;
    hostRef.value?.focus();
  },
);

provide(ACTION_LIST_CONTAINER_CONTEXT, {
  listRole: 'menu',
  itemRole:
    props.selectionMode === 'multiple'
      ? 'menuitemcheckbox'
      : props.selectionMode === 'single'
        ? 'menuitemradio'
        : 'menuitem',
  onAfterSelect: () => {
    open.value = false;
  },
});

// TODO: provide in context listRole, listLabelledBy (anchor id)

const slots = useSlots();

function focusInStrategy(): HTMLElement | undefined {
  return containerRef.value?.$el?.querySelector('[aria-checked="true"]') || undefined;
}

defineRender(() => {
  return h(
    ObFloatingSurface,
    {
      active: open.value,
      'onUpdate:active': (value) => (open.value = value),
      ref: containerRef,
      focusTrapOptions: { returnFocusOnDeactivate: true, ...props.focusTrapOptions },
      focusZoneOptions: { focusOutBehavior: 'wrap', focusInStrategy, ...props.focusZoneOptions },
      height: props.height,
      maxHeight: props.maxHeight,
      minHeight: props.minHeight,
      width: props.width,
      maxWidth: props.maxWidth,
      minWidth: props.minWidth,
    },
    {
      default: slots.default,
      // TODO: how to set scope type better?
      host: (scope: any) => {
        const [host] = slots.host?.(scope) ?? [];

        if (!host) {
          return null;
        }

        return h(cloneVNode(host, {}), { ref: hostRef });
      },
    },
  );
});
</script>
