import type { Ref } from 'vue';
import { useEventListener, unrefElement } from '@vueuse/core';

// `@click.self` works well but not in a case when a user starts click on some child element
// and then releases mouse button on the element with attached handler.
// This composable solves the issue by checking where exactly clicking was started.

export function useTrueSelfClick(
  element: Ref<HTMLElement | undefined>,
  callback: (event: MouseEvent) => void,
) {
  let clickStarted = false;

  function check(event: MouseEvent) {
    return event.target === unrefElement(element);
  }

  useEventListener(element, 'mousedown', (event: MouseEvent) => {
    if (check(event)) {
      clickStarted = true;
    }
  });

  useEventListener(document, 'mouseup', (event: MouseEvent) => {
    if (clickStarted && check(event)) {
      callback(event);
    }

    clickStarted = false;
  });
}
