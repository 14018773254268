import type { InjectionKey, MaybeRefOrGetter } from 'vue';
import type { AriaRole } from '../../../shared/types';

interface ActionListContext {
  selectionMode?: MaybeRefOrGetter<'single' | 'multiple' | undefined>;
  itemRole?: MaybeRefOrGetter<AriaRole | undefined>;
  compact?: MaybeRefOrGetter<boolean | undefined>;
  sortable?: MaybeRefOrGetter<boolean | undefined>;
  onAfterSelect?: () => void;
}

export const ACTION_LIST_CONTEXT: InjectionKey<ActionListContext> = Symbol(
  __DEV__ ? 'ActionList context' : '',
);
