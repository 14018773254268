<script lang="ts" setup>
import {
  IconAlertCircle,
  IconDotsCircleHorizontal,
  IconCircleCheck,
  IconCircleX,
  IconInfoCircle,
} from '@tabler/icons-vue';
import { hasSlotContent } from '../../utils';
import type { Status } from '../../shared/types';

interface Props {
  variant?: Status;
}

const props = withDefaults(defineProps<Props>(), {
  variant: undefined,
});
</script>

<template>
  <span
    :class="[
      $style.root,
      {
        [$style.info]: props.variant === 'info',
        [$style.warning]: props.variant === 'warning',
        [$style.danger]: props.variant === 'danger',
        [$style.success]: props.variant === 'success',
      },
    ]"
  >
    <span :class="$style.icon">
      <IconInfoCircle v-if="props.variant === 'info'" aria-hidden="true" />
      <IconAlertCircle v-else-if="props.variant === 'warning'" aria-hidden="true" />
      <IconCircleX v-else-if="props.variant === 'danger'" />
      <IconCircleCheck v-else-if="props.variant === 'success'" aria-hidden="true" />
      <IconDotsCircleHorizontal v-else aria-hidden="true" />
    </span>
    <span v-if="hasSlotContent($slots.default)" :class="$style.text"><slot /></span>
  </span>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';

.root {
  display: inline-flex;
  vertical-align: middle;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.icon {
  display: flex;
  font-size: 20px;
  width: 1em;
  height: 1em;
  color: colors.$primary-80;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.icon + .text {
  margin-left: 8px;
}

.info .icon {
  color: colors.$status-info;
}

.warning .icon {
  color: colors.$status-warning;
}

.danger .icon {
  color: colors.$status-danger;
}

.success .icon {
  color: colors.$status-success;
}
</style>
