import type { BreakpointBasedProp } from '../shared/types';
import { Breakpoint } from './../shared/types';

// Property scheme:
// [value, { breakpoint: value }]

// Class name scheme:
// _key-{breakpoint}-value
// _value-{breakpoint} (for boolean)

// <Component :size="[12, { md: 8, lg: 6 }]" />
// ->
// <div class="_size-12 _size-md-8 _size-lg-6" />

// <Component :wrap="['nowrap', { md: 'wrap' }]" />
// ->
// <div class="_nowrap _wrap-md" />

export function breakpointBasedPropToClassNames<T = unknown>(
  prop: BreakpointBasedProp<T>,
  key?: string,
  prefix = '_',
): string[] {
  const [base, breakpoints] = Array.isArray(prop) ? prop : [prop];
  const result = [];

  const keyPart = key ? `${key}-` : '';

  if (base) {
    result.push(`${prefix}${keyPart}${base}`);
  }

  if (breakpoints) {
    (Object.keys(breakpoints) as Breakpoint[]).forEach((bp) => {
      if (breakpoints[bp]) {
        result.push(
          keyPart
            ? `${prefix}${keyPart}${bp}-${breakpoints[bp]}`
            : `${prefix}${breakpoints[bp]}-${bp}`,
        );
      }
    });
  }

  return result;
}
