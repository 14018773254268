<script lang="ts" setup>
import { computed, h, useCssModule, useSlots } from 'vue';
import { Keys } from '../../shared/enums';
import { injectStrict, validateAsChild } from '../../utils';
import { OB_TABS_CONTEXT } from './shared';

interface Props {
  disabled?: boolean;
  asChild?: boolean;
  value: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  asChild: false,
});

const { setValue, isValueSelected, id, variant, selectionFollowFocus } = injectStrict(
  OB_TABS_CONTEXT,
  undefined,
  '<ObTabsButton /> must be a child of <ObTabs /> component.',
);

const selected = computed(() => isValueSelected(props.value));

function onClick() {
  setValue(props.value);
}

function onKeyDown(event: KeyboardEvent) {
  if (event.key === Keys.Enter) {
    setValue(props.value);
  }
}

function onFocus() {
  if (selectionFollowFocus.value) {
    setValue(props.value);
  }
}

const slots = useSlots();
const style = useCssModule();

const attrs = computed(() => ({
  id: `${id}-tab-${props.value}`,
  tabindex: selected.value ? 0 : -1,
  role: 'tab',
  ['aria-controls']: selected.value ? `${id}-panel-${props.value}` : undefined,
  ['aria-selected']: selected.value,
  onClick,
  onKeydown: onKeyDown,
  onFocus,
  class: [
    variant.value === 'secondary' ? style.tabSecondary : style.tabPrimary,
    { [style.selected]: selected.value },
  ],
}));

defineRender(() => {
  const children = slots.default?.();

  if (props.asChild) {
    if (!children || !validateAsChild(children)) {
      // TODO: add warning?
      return null;
    }
    return h(children?.[0], attrs.value);
  }

  return h(
    'button',
    {
      ...attrs.value,
      type: 'button',
      disabled: props.disabled,
    },
    children,
  );
});
</script>

<style lang="scss" module>
@use '../../styles/shared';
@use '../../styles/colors';
@use '../../styles/iconography';
@use '../../styles/typography';

.tabPrimary,
.tabSecondary {
  @include shared.reset-button();
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  font-family: typography.$font-family-primary;
  overflow: hidden;
}

.tabPrimary {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0 2px 12px;
  color: colors.$primary;
  border-radius: shared.$border-radius-s;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
  }

  &:hover::after {
    background-color: #dad4fc; // TODO: design token
  }

  &.selected::after {
    background-color: #907ff5; // TODO: design token
  }
}

.tabSecondary {
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 20px;
  height: 32px;
  font-weight: 500;
  color: colors.$primary;
  border: 1px solid colors.$surface-16;

  &:hover {
    background-color: #f8f7fe; // TODO: token
  }

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
    z-index: 10;
  }

  &.selected {
    color: colors.$white;
    background-color: #907ff5; // TODO: token

    &:hover {
      background-color: #7a68e3; // TODO: token
    }
  }

  &:active,
  &.selected:active {
    color: colors.$primary;
    background-color: #cbc3fa; // TODO: token
  }

  & + & {
    margin-left: -1px;
  }

  &:first-child {
    border-radius: shared.$border-radius-s 0 0 shared.$border-radius-s;
  }

  &:last-child {
    border-radius: 0 shared.$border-radius-s shared.$border-radius-s 0;
  }
}
</style>
