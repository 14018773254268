<script lang="ts" setup>
import { computed } from 'vue';
import { BreakpointBasedProp } from '../../shared/types';
import { breakpointBasedPropToClassNames } from '../../utils';
import type {
  FlexGridItemAlign,
  FlexGridItemOffset,
  FlexGridItemOrder,
  FlexGridItemSize,
} from './types';

type ObFlexGridItemProps = {
  align?: BreakpointBasedProp<FlexGridItemAlign>;
  offset?: BreakpointBasedProp<FlexGridItemOffset>;
  order?: BreakpointBasedProp<FlexGridItemOrder>;
  size?: BreakpointBasedProp<FlexGridItemSize>;
};

const props = withDefaults(defineProps<ObFlexGridItemProps>(), {
  align: undefined,
  offset: undefined,
  order: undefined,
  size: undefined,
});

const classes = computed(() => [
  'ob-flex-grid__item',
  ...breakpointBasedPropToClassNames(props.align, 'align'),
  ...breakpointBasedPropToClassNames(props.offset, 'offset'),
  ...breakpointBasedPropToClassNames(props.order, 'order'),
  ...breakpointBasedPropToClassNames(props.size, 'size'),
]);
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>
