<script lang="ts" setup generic="T = any">
import { ref, computed, watch, nextTick } from 'vue';
import type { VNode, StyleValue, VNodeProps } from 'vue';
import { useVModel, onClickOutside, unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useFloating, offset, flip, size, autoUpdate, shift } from '@floating-ui/vue';
import type { Middleware, Placement } from '@floating-ui/vue';
import { IconSearch, IconX } from '@tabler/icons-vue';
import { toString } from 'lodash-es';
import { useFocusZone, useZIndex, useFocusScope } from '../../../composables';
import {
  ObSeparator,
  ObInputText,
  ObIconButton,
  ObScrollableContainer,
  ObTooltip,
} from '../../../components';
import { getValueByKey, areValuesEqual } from '../../../utils';
import { ObActionList, ObActionListItem } from '../action-list';

interface Option {
  index: number;
  key: string;
  label: string;
  disabled: boolean;
  value: unknown;
  selected: boolean;
  tooltip?: string;
  origin: T;
}

interface Props {
  flip?: boolean;
  modelValue?: unknown | unknown[] | null;
  open?: boolean;
  padding?: number;
  placement?: Placement;
  offset?: number;
  options?: T[];
  optionDisabled?: string | ((option: T) => boolean);
  optionLabel?: string | ((option: T) => string);
  optionTooltip?: string | ((option: T) => string);
  optionValue?: string | ((option: T) => unknown);
  search?: string;
  searchPlaceholder?: string;
  selectionMode?: 'single' | 'multiple';
  subtitle?: string;
  title?: string;
  trackValueBy?: string | ((value: unknown) => unknown);
  withSearch?: boolean;
  withSelectAll?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  flip: true,
  modelValue: () => [],
  open: false,
  offset: 4,
  options: () => [],
  optionDisabled: undefined,
  optionLabel: undefined,
  optionTooltip: undefined,
  optionValue: undefined,
  padding: 12,
  placement: 'bottom-start',
  search: '',
  searchPlaceholder: undefined,
  selected: () => [],
  selectionMode: undefined,
  subtitle: undefined,
  title: undefined,
  trackValueBy: undefined,
  withSearch: false,
  withSelectAll: false,
});

const emit = defineEmits<{
  'update:open': [open: boolean];
  'update:modelValue': [value: unknown];
  'update:search': [search: string];
  select: [option: T];
}>();

defineSlots<{
  default?: () => VNode;
  host?: (props: { open: boolean; hostProps: VNodeProps }) => VNode;
  options?: (props: { options: Option[] }) => VNode;
  optionIcon?: (props: { option: Option }) => VNode;
  optionDescription?: (props: { option: Option }) => VNode;
  optionLabel?: (props: { option: Option }) => VNode;
  noOptions?: () => VNode;
  beforeOptions?: () => VNode;
  afterOptions?: () => VNode;
}>();

const open = useVModel(props, 'open', emit, {
  passive: true,
});
const search = useVModel(props, 'search', emit, {
  passive: true,
});

const hostRef = ref<HTMLElement | null>(null);
const floatingRef = ref<HTMLElement | null>(null);

function activate() {
  open.value = true;
  emit('update:open', open.value);
}

function deactivate() {
  open.value = false;
  emit('update:open', open.value);
}

function focusHost() {
  unrefElement(hostRef)?.focus();
}

function onHostKeyDown(event: KeyboardEvent) {
  if (event.defaultPrevented) {
    return;
  }
  if (!open.value && ['ArrowDown', 'ArrowUp', ' ', 'Enter'].includes(event.key)) {
    event.preventDefault();
    activate();
  }
}

function onHostClick(event: PointerEvent) {
  if (event.defaultPrevented || event.button !== 0) {
    return;
  }

  if (open.value) {
    deactivate();
    return;
  }

  activate();
}

function deactivateAndReturnFocus() {
  deactivate();
  nextTick(() => {
    focusHost();
  });
}

function onFloatingKeyDown(event: KeyboardEvent) {
  if (event.defaultPrevented) {
    return;
  }

  if (event.key === 'Escape') {
    event.preventDefault();
    deactivateAndReturnFocus();
  }
}

function onClickClose() {
  deactivateAndReturnFocus();
}

const middleware = computed<Middleware[]>(() => {
  const result = [
    // TODO: size?
    size({
      padding: props.padding,
      // apply({ elements, availableWidth, availableHeight }) {},
    }),
    offset(() => props.offset),
  ];

  // TODO use autoplacement instead? https://floating-ui.com/docs/autoplacement#conflict-with-flip
  if (props.flip) {
    result.push(
      flip({
        padding: props.padding,
      }),
    );
  }

  result.push(
    shift({
      padding: props.padding,
    }),
  );

  return result;
});

const { floatingStyles, placement } = useFloating(hostRef, floatingRef, {
  placement: props.placement,
  middleware,
  whileElementsMounted: autoUpdate,
  open,
  transform: false,
});

useFocusZone(floatingRef, { disabled: computed(() => !open.value) });

const { zIndex } = useZIndex({ active: open });

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(floatingRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: false,
  fallbackFocus: () => unrefElement(floatingRef) ?? 'body',
});

const composedFloatingStyles = computed(() => {
  const result: StyleValue = {
    ...floatingStyles.value,
  };

  if (zIndex.value) {
    result.zIndex = zIndex.value.toString();
  }

  return result;
});

const { active: focusScopeActive } = useFocusScope(floatingRef);

onClickOutside(
  floatingRef,
  () => {
    // Ignore click outside for focus scope
    if (focusScopeActive.value) {
      return;
    }

    deactivate();
  },
  { ignore: [hostRef] },
);

watch(
  open,
  (value, oldValue) => {
    if (value) {
      nextTick(() => {
        if (!open.value) {
          return;
        }
        activateTrap();
      });
      return;
    }

    if (typeof oldValue === 'undefined') {
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

const modelValue = useVModel(props, 'modelValue', emit, {
  passive: true,
});

const options = computed<Option[]>(() =>
  props.options.map((option, index) => {
    const value = props.optionValue ? getValueByKey(option, props.optionValue) : option;

    let selected = false;

    if (props.selectionMode === 'single') {
      selected = areValuesEqual(value, modelValue.value, props.trackValueBy);
    } else if (props.selectionMode === 'multiple' && Array.isArray(modelValue.value)) {
      selected = modelValue.value.some((item) => areValuesEqual(item, value, props.trackValueBy));
    }

    return {
      index,
      key: toString((props.trackValueBy && getValueByKey(value, props.trackValueBy)) ?? index),
      label: toString(props.optionLabel ? getValueByKey(option, props.optionLabel) : option),
      tooltip: props.optionTooltip ? getValueByKey(option, props.optionTooltip) : undefined,
      disabled: !!(props.optionDisabled ? getValueByKey(option, props.optionDisabled) : false),
      value,
      selected,
      origin: option,
    };
  }),
);

function toggleOption(option: Option) {
  if (!props.selectionMode) {
    emit('select', props.options[option.index]);
    deactivateAndReturnFocus();
    return false;
  }

  if (props.selectionMode === 'single') {
    modelValue.value = option.value;
    return;
  }

  if (!Array.isArray(modelValue.value)) {
    modelValue.value = [];
  }

  if (Array.isArray(modelValue.value)) {
    if (option.selected) {
      modelValue.value = modelValue.value.filter(
        (item) => !areValuesEqual(item, option.value, props.trackValueBy),
      );
    } else {
      modelValue.value.push(option.value);
    }

    return;
  }

  modelValue.value = option.selected ? [] : [option.value];
}

const basePlacement = computed(() => placement.value.split('-')[0]);

const hostProps = computed(() => ({
  'aria-haspopup': 'true',
  'aria-expanded': open.value ? 'true' : undefined,
  tabIndex: '0',
  onClick: onHostClick,
  onKeydown: onHostKeyDown,
  // TODO: fix type
  ref: (el: any) => {
    hostRef.value = unrefElement(el);
  },
}));

const allSelected = computed(() => options.value.every(({ selected }) => selected));

const someSelected = computed(() => options.value.some(({ selected }) => selected));

function toggleSelectAllOptions(selected: boolean) {
  options.value.forEach((option: Option) => {
    if ((selected && !option.selected) || (!selected && option.selected)) {
      toggleOption(option);
    }
  });
}

// TODO: handle tab keydown
</script>

<template>
  <slot name="host" v-bind="{ open, hostProps }" />
  <Teleport v-if="open" to="body">
    <Transition
      :enter-from-class="$style.transitionEnterFrom"
      :enter-active-class="$style.transitionEnterActive"
      :leave-active-class="$style.transitionLeaveActive"
      :leave-to-class="$style.transitionLeaveTo"
      appear
    >
      <div
        ref="floatingRef"
        :class="[
          $style.root,
          {
            [$style.placementTop]: basePlacement === 'top',
            [$style.placementLeft]: basePlacement === 'left',
            [$style.placementRight]: basePlacement === 'right',
            [$style.placementBottom]: basePlacement === 'bottom',
          },
        ]"
        tabindex="-1"
        :style="composedFloatingStyles"
        role="dialog"
        @keydown="onFloatingKeyDown"
      >
        <div :class="$style.header">
          <div :class="$style.headerTop">
            <div v-if="props.title" :class="$style.title">{{ props.title }}</div>
            <ObIconButton variant="tertiary" size="xs" aria-label="Close" @click="onClickClose">
              <IconX aria-hidden="true" />
            </ObIconButton>
          </div>
          <div v-if="props.subtitle" :class="$style.subtitle">
            {{ props.subtitle }}
          </div>
          <div v-if="props.withSearch">
            <ObInputText v-model="search" size="s" :placeholder="props.searchPlaceholder">
              <template #icon>
                <IconSearch aria-hidden="true" />
              </template>
            </ObInputText>
          </div>
        </div>
        <ObSeparator />
        <ObScrollableContainer light>
          <div :class="$style.list">
            <slot name="options" v-bind="{ options }">
              <template v-if="options.length">
                <slot name="beforeOptions" />
                <ObActionList :selection-mode="props.selectionMode" compact>
                  <ObActionListItem
                    v-if="props.withSelectAll && props.selectionMode === 'multiple' && !search"
                    :selected="allSelected"
                    selection-mode="multiple"
                    compact
                    :indeterminate="!allSelected && someSelected"
                    @select="toggleSelectAllOptions(!allSelected)"
                  >
                    <span :class="$style.selectAllLabel">Select all</span>
                  </ObActionListItem>
                  <ObTooltip
                    v-for="option in options"
                    :key="option.key"
                    :disabled="!option.tooltip"
                  >
                    <template #host="{ hostProps }">
                      <ObActionListItem
                        :selected="option.selected"
                        :disabled="option.disabled"
                        v-bind="hostProps"
                        @select="toggleOption(option)"
                      >
                        <template #icon>
                          <slot name="optionIcon" v-bind="{ option }" />
                        </template>
                        <template #description>
                          <slot name="optionDescription" v-bind="{ option }" />
                        </template>
                        <slot name="optionLabel" v-bind="{ option }">{{ option.label }}</slot>
                      </ObActionListItem>
                    </template>
                    {{ option.tooltip }}
                  </ObTooltip>
                </ObActionList>
                <slot name="afterOptions" />
              </template>
              <div v-else>
                <slot name="noOptions">No options</slot>
              </div>
            </slot>
          </div>
        </ObScrollableContainer>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../../styles/colors';
@use '../../../styles/shared';
@use '../../../styles/typography';
@use '../../../styles/elevation';

.root {
  @include elevation.shadow(3);
  box-sizing: border-box;
  background: colors.$white;
  border: 1px solid colors.$surface-6;
  color: colors.$primary;
  position: absolute;
  min-width: 300px;
  max-width: 480px;
  max-height: 480px;
  width: auto;
  overflow: hidden;
  border-radius: shared.$border-radius-m;
  font-family: typography.$font-family-primary;
  display: flex;
  flex-direction: column;
}

.transitionEnterFrom,
.transitionLeaveTo {
  opacity: 0;
  &.placementLeft {
    transform: translateX(-10px);
  }
  &.placementRight {
    transform: translateX(10px);
  }
  &.placementTop {
    transform: translateY(-10px);
  }
  &.placementBottom {
    transform: translateY(10px);
  }
}

.transitionEnterActive,
.transitionLeaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.15s;
}

.transitionEnterActive {
  transition-timing-function: ease-out;
}

.transitionLeaveActive {
  transition-timing-function: ease-in;
}

.header {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: end;
}

.title {
  color: #021148;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  padding-right: 12px;
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #9aa0b6;
}

.list {
  padding: 12px 8px;
}

.selectAllLabel {
  font-weight: 500;
}
</style>
