<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  watchEffect,
  ref,
  provide,
  h,
  useSlots,
  withDirectives,
  vShow,
  useId,
} from 'vue';
import { injectStrict } from '../../utils';
import { OB_ABSTRACT_DISCLOSURE_CONTEXT, OB_ABSTRACT_DISCLOSURE_PANEL_CONTEXT } from './shared';

interface Props {
  id?: string;
  unmount?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => useId() as string,
  unmount: true,
});

const { panelRef, panelId, expanded, collapse } = injectStrict(
  OB_ABSTRACT_DISCLOSURE_CONTEXT,
  undefined,
  '<ObAbstractDisclosurePanel /> must be a child of <ObAbstractDisclosure /> component.',
);

onMounted(() => {
  panelId.value = props.id;
});

onUnmounted(() => {
  panelId.value = null;
});

provide(OB_ABSTRACT_DISCLOSURE_PANEL_CONTEXT, panelId);

const elementRef = ref<HTMLElement | null>(null);

watchEffect(() => {
  panelRef.value = elementRef.value;
});

const slots = useSlots();

defineRender(() => {
  if (props.unmount && !expanded.value) {
    return null;
  }

  const children =
    slots.default &&
    slots.default({
      expanded: expanded.value,
      collapse,
    });

  if (!children?.length) {
    return null;
  }

  const vNode = h(children[0], { ref: elementRef, id: props.id });

  return props.unmount ? vNode : withDirectives(vNode, [[vShow, expanded.value]]);
});
</script>
