<script lang="ts" setup>
import { toRef, computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { useConfig } from '../../composables';
import { ObSpace } from '../space';
import { ObSelect } from '../select';
import { ObPagination } from '../pagination';
import { ObFlexGrid, ObFlexGridItem } from '../flex-grid';

interface Props {
  labelRowsPerPage?: string;
  labelDisplayedRows?: (from: number, to: number, total: number) => string;
  total?: number;
  page?: number;
  pageSize?: number;
  pageSizeOptions?: number[];
}

const props = withDefaults(defineProps<Props>(), {
  labelRowsPerPage: undefined,
  labelDisplayedRows: undefined,
  total: 100,
  page: 1,
  pageSize: 25,
  pageSizeOptions: () => [],
});

const emit = defineEmits<{
  'update:page': [value: number];
  'update:pageSize': [value: number];
}>();

const config = useConfig();

const page = useVModel(props, 'page', emit, {
  passive: true,
});

const pageSize = useVModel(props, 'pageSize', emit, {
  passive: true,
});

const total = toRef(props, 'total');

const hasArrows = computed(() => total.value > pageSize.value);

const labelRowsPerPage = computed(() => {
  return props.labelRowsPerPage ?? config?.value?.i18n?.TablePagination?.labelRowsPerPage ?? 'Rows';
});
const labelDisplayedRows = computed(() => {
  const customLabel =
    props.labelDisplayedRows ?? config?.value?.i18n?.TablePagination?.labelDisplayedRows;

  const currentPageStart = (page.value - 1) * pageSize.value + 1;
  const currentPageEnd = Math.min(page.value * pageSize.value, total.value);

  if (customLabel) {
    return customLabel(currentPageStart, currentPageEnd, total.value);
  }

  return `${currentPageStart}-${currentPageEnd} of ${total.value} items`;
});
</script>

<template>
  <ObFlexGrid justify-content="end" spacing="4" align-items="center">
    <ObFlexGridItem v-if="props.pageSizeOptions.length">
      <ObSpace spacing="4" align-y="center">
        <div>{{ labelRowsPerPage }}:</div>
        <div>
          <ObSelect v-model="pageSize" :options="props.pageSizeOptions" size="s" />
        </div>
      </ObSpace>
    </ObFlexGridItem>
    <ObFlexGridItem size="auto">
      <ObSpace v-if="total" spacing="4" align-y="center">
        <div>{{ labelDisplayedRows }}</div>
        <ObPagination v-if="hasArrows" v-model="page" :page-size="pageSize" :total="total" />
      </ObSpace>
    </ObFlexGridItem>
  </ObFlexGrid>
</template>
