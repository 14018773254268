<script lang="ts" setup>
import {
  IconCircleCheck,
  IconInfoCircle,
  IconAlertTriangle,
  IconAlertOctagon,
  IconX,
} from '@tabler/icons-vue';

interface Props {
  closable?: boolean;
  variant?: 'info' | 'positive' | 'warning' | 'negative' | null;
}

const props = withDefaults(defineProps<Props>(), {
  closable: false,
  variant: null,
});

const emit = defineEmits<{
  close: [];
}>();

function close() {
  emit('close');
}

const iconAttrs = {
  ariaHidden: true,
  size: 32,
  strokeWidth: 1.5,
};
</script>

<template>
  <div
    :class="[
      $style.root,
      {
        [$style.variantInfo]: props.variant === 'info',
        [$style.variantPositive]: props.variant === 'positive',
        [$style.variantWarning]: props.variant === 'warning',
        [$style.variantNegative]: props.variant === 'negative',
      },
    ]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div :class="$style.icon">
      <slot name="icon">
        <IconInfoCircle v-if="props.variant === 'info'" v-bind="iconAttrs" />
        <IconCircleCheck v-else-if="props.variant === 'positive'" v-bind="iconAttrs" />
        <IconAlertTriangle v-else-if="props.variant === 'warning'" v-bind="iconAttrs" />
        <IconAlertOctagon v-else-if="props.variant === 'negative'" v-bind="iconAttrs" />
        <IconInfoCircle v-else v-bind="iconAttrs" />
      </slot>
    </div>
    <div :class="$style.content"><slot /></div>
    <button v-if="closable" type="button" :class="$style.close" aria-label="Close" @click="close">
      <IconX v-bind="iconAttrs" />
    </button>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';
@use '../../styles/shared';
@use '../../styles/elevation';
@use '../../styles/iconography';

.root {
  font-family: typography.$font-family-primary;
  display: flex;
  border-radius: 10px;
  padding: 12px 24px;
  border: 1px solid #d7d9e2;
  background: #f6f6f8;
  color: #35416d;
  gap: 12px;
}

.icon {
  width: 32px;
  height: 32px;
  color: #021148;
}

.content {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 0 0;
  flex-basis: 0;
  flex-grow: 1;
}

.close {
  @include shared.reset-button();
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: shared.$border-radius-s;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  width: 32px;
  height: 32px;
  flex: none;
  padding: 4px;
  color: #021148;

  &::before {
    @include shared.coverer();
    content: '';
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: inherit;
  }

  &:focus-visible {
    &::before {
      border-color: colors.$hyperlink;
    }
  }

  > svg {
    width: 100%;
    height: 100%;
  }
}

.variantInfo {
  border-color: #085cd9;
  background-color: #f3f7fd;

  .icon {
    color: #085cd9;
  }
}

.variantPositive {
  border-color: #25b592;
  background-color: #f4fbfa;

  .icon {
    color: #25b592;
  }
}

.variantWarning {
  border-color: #ff824d;
  background-color: #fff9f6;

  .icon {
    color: #ff824d;
  }
}

.variantNegative {
  border-color: #e42828;
  background-color: #fef4f4;

  .icon {
    color: #e42828;
  }
}
</style>
