<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useVModel, unrefElement, syncRef } from '@vueuse/core';
import { IconLoader2, IconChevronDown } from '@tabler/icons-vue';
import { useChildrenFocusControl, useOptionsList, useDropdown } from '../../composables';
import type { UseOptionsListOption } from '../../composables';
import { Keys } from '../../shared/enums';
import { ObPrimitiveInput } from '../primitive-input';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { ObScrollableContainer } from '../scrollable-container';
import ObComboBoxOption from './ob-combo-box-option.vue';

// TODO: Disabled state
// TODO: Disabled option
// TODO: aria-label or aria-labeledby for <input> and listbox
// TODO: wait floatingPositioned before start transition
// TODO: virtual scroll
// TODO: when options count > options.limit show text about it (e.g. "100 of 1000 options are visible. Type to find options")

interface Props {
  disabled?: boolean;
  externalSearch?: boolean;
  id?: string;
  invalid?: boolean;
  loading?: boolean;
  modelValue?: any;
  options: Array<unknown>;
  optionsLimit?: number;
  optionDisabled?: string | ((option: any) => boolean);
  optionLabel?: string | ((option: any) => string);
  optionValue?: string | ((option: any) => unknown);
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  trackValueBy?: string | ((value: unknown) => unknown);
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  externalSearch: false,
  id: undefined,
  invalid: false,
  loading: false,
  modelValue: null,
  options: () => [],
  optionsLimit: 300,
  optionDisabled: undefined,
  optionLabel: undefined,
  optionValue: undefined,
  placeholder: '',
  size: 'm',
  trackValueBy: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: unknown];
  search: [value: string];
}>();

const modelValue = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
  defaultValue: null,
});

const {
  containerRef,
  dropdownRef,
  focused,
  active: dropdownActive,
  visible: dropdownVisible,
  styles: dropdownStyles,
  onPositioned: onDropdownPositioned,
  placement: dropdownPlacement,
  id: dropdownId,
  moveFocusOut,
} = useDropdown();

const { focusFirst, focusLast, focusNext, focusPrevious } = useChildrenFocusControl({
  root: dropdownRef,
});

const searchText = ref('');

const { options, selectedOption, normalizeForTextSearch } = useOptionsList({
  items: computed(() => props.options),
  searchText: computed(() => (!props.externalSearch ? searchText.value : '')),
  selectedItems: computed(() => (modelValue.value !== null ? [modelValue.value] : [])),
  optionLabel: props.optionLabel,
  optionValue: props.optionValue,
  optionsLimit: props.optionsLimit,
  trackValueBy: props.trackValueBy,
});

const inputRef = ref();
const inputText = ref('');

// When select option set it's value to input
syncRef(selectedOption, inputText, {
  direction: 'ltr',
  transform: {
    ltr: (value) => (value ? value.label : ''),
  },
});

function focusInput() {
  unrefElement(inputRef)?.focus();
}

function selectOption(option: UseOptionsListOption): void {
  modelValue.value = option.value;
  inputText.value = option.label;
  searchText.value = ''; // Reset search text
}

watch(focused, (value) => {
  if (value || !document.hasFocus()) {
    return;
  }

  dropdownActive.value = false;

  const normalizedSearch = normalizeForTextSearch(inputText.value);

  // Reset model value if field was cleared
  if (!normalizedSearch) {
    return;
  }

  // Restore text input value
  if (selectedOption.value) {
    inputText.value = selectedOption.value.label;
    searchText.value = '';
    return;
  }

  // Try to select matching option
  const matchingOption = options.value.find(
    ({ label }) => normalizeForTextSearch(label) === normalizedSearch,
  );

  if (matchingOption) {
    selectOption(matchingOption);
  }
});

function onInput(event: Event) {
  const { value } = event.target as HTMLInputElement;
  searchText.value = value;
  emit('search', searchText.value);

  if (!value) {
    modelValue.value = null;
  }
}

function onOptionClick(event: MouseEvent, option: UseOptionsListOption): void {
  selectOption(option);
  focusInput();
  dropdownActive.value = false;
}

function onOptionKeydown(event: KeyboardEvent, option: UseOptionsListOption): void {
  if (event.key !== Keys.Enter) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  selectOption(option);
  focusInput();
  dropdownActive.value = false;
}

function onInputKeydown(event: KeyboardEvent) {
  const editingKeys: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

  switch (event.key) {
    case Keys.Escape: {
      event.stopPropagation();
      event.preventDefault();
      dropdownActive.value = false;
      break;
    }
    case Keys.ArrowUp:
    case Keys.ArrowDown: {
      event.preventDefault();
      event.stopPropagation();
      dropdownActive.value = true;

      onDropdownPositioned(() => {
        if (event.key === Keys.ArrowUp) {
          focusLast();
          return;
        }

        focusFirst();
      });

      break;
    }
    default:
      if (
        !dropdownActive.value &&
        !event.defaultPrevented &&
        (event.key.length === 1 || editingKeys.includes(event.key))
      ) {
        dropdownActive.value = true;
      }
      break;
  }
}

function onDropdownKeydown(event: KeyboardEvent) {
  const editingKeys: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

  switch (event.key) {
    case Keys.Escape: {
      event.stopPropagation();
      event.preventDefault();
      focusInput();
      dropdownActive.value = false;

      break;
    }
    case Keys.ArrowUp:
    case Keys.ArrowDown: {
      event.preventDefault();
      event.stopPropagation();

      if (event.key === Keys.ArrowUp) {
        focusPrevious();
        return;
      }

      focusNext();

      break;
    }
    case Keys.Home:
    case Keys.PageUp: {
      event.preventDefault();
      event.stopPropagation();
      focusFirst();
      break;
    }
    case Keys.End:
    case Keys.PageDown: {
      event.preventDefault();
      event.stopPropagation();
      focusLast();
      break;
    }
    case Keys.Tab: {
      // Try to move focus from dropdown to the next/prev focusable element.
      // If nothing found (multi select is the last or first element on the page
      // return focus to the multi select element.
      event.preventDefault();
      event.stopPropagation();

      if (!moveFocusOut(event.shiftKey)) {
        focusInput();
      }

      break;
    }
    default: {
      if (!event.defaultPrevented && (event.key.length === 1 || editingKeys.includes(event.key))) {
        focusInput();
      }

      break;
    }
  }
}

function onArrowClick(event: MouseEvent) {
  if (!focused.value) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  dropdownActive.value = !dropdownActive.value;
}

function onWrapperMouseDown(event: MouseEvent) {
  if (event.target === unrefElement(inputRef)) {
    return;
  }

  event.preventDefault();
  focusInput();
}

function onWrapperClick(event: MouseEvent) {
  event.preventDefault();
  dropdownActive.value = true;
}
</script>

<template>
  <ObPrimitiveInput
    ref="containerRef"
    :size="props.size"
    :focused="focused"
    @mousedown="onWrapperMouseDown"
    @click="onWrapperClick"
  >
    <div :class="$style.root">
      <div :class="$style.main">
        <input
          :id="props.id"
          ref="inputRef"
          v-model="inputText"
          type="text"
          :class="$style.input"
          aria-autocomplete="list"
          :aria-controls="dropdownId"
          :aria-expanded="dropdownActive"
          aria-haspopup="listbox"
          :aria-owns="dropdownId"
          autocomplete="off"
          :disabled="disabled"
          :placeholder="props.placeholder"
          role="combobox"
          @keydown="onInputKeydown"
          @input="onInput"
        />
      </div>
      <button
        type="button"
        :class="[$style.arrow, { [$style.arrowRotated]: dropdownActive }]"
        :aria-controls="dropdownId"
        :aria-expanded="dropdownActive"
        aria-haspopup="listbox"
        tabindex="-1"
        @click="onArrowClick"
      >
        <IconChevronDown aria-hidden="true" />
      </button>
    </div>
  </ObPrimitiveInput>
  <Teleport to="body">
    <Transition
      name="_transition"
      @before-enter="dropdownVisible = true"
      @after-leave="dropdownVisible = false"
    >
      <ObScrollableContainer
        v-if="dropdownActive"
        ref="dropdownRef"
        tabindex="-1"
        :style="dropdownStyles"
        class="ob-combo-box-dropdown"
        :class="{ [`_placement-${dropdownPlacement}`]: dropdownPlacement }"
        light
        @keydown="onDropdownKeydown"
      >
        <div v-if="props.loading" class="ob-combo-box-loader">
          <div class="ob-combo-box-loader__icon">
            <IconLoader2 aria-hidden="true" />
          </div>
        </div>
        <ul v-else-if="options.length" :id="dropdownId" role="listbox" class="ob-combo-box-options">
          <ObComboBoxOption
            v-for="option in options"
            :key="option.key"
            :label="option.label"
            :selected="option.selected"
            @click="onOptionClick($event, option)"
            @keypress="onOptionKeydown($event, option)"
          />
        </ul>
        <div v-else class="ob-combo-box-message">
          <slot name="noOptions">No options found</slot>
        </div>
        <div />
        <!-- This div is needed to focus last element -->
      </ObScrollableContainer>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  box-sizing: border-box;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.input {
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  border-radius: inherit;
  background: none;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  border: none;
  padding: 0;
  width: 100%;

  &::placeholder {
    color: colors.$surface-40;
  }
}

.arrow {
  @include shared.reset-button();
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}
</style>

<style lang="scss">
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/elevation';
@use '../../styles/typography';

.ob-combo-box-dropdown {
  @include elevation.shadow(3);
  border-radius: shared.$border-radius-s;
  background: colors.$white;
  border: 1px solid colors.$surface-6;

  &._transition-enter-from,
  &._transition-leave-to {
    opacity: 0;

    &._placement-left {
      transform: translateX(-10px);
    }

    &._placement-right {
      transform: translateX(10px);
    }

    &._placement-top {
      transform: translateY(-10px);
    }

    &._placement-bottom {
      transform: translateY(10px);
    }
  }

  &._transition-enter-active,
  &._transition-leave-active {
    transition-property: opacity, transform;
    transition-duration: 0.2s;
  }

  &._transition-enter-active {
    transition-timing-function: ease-out;
  }

  &._transition-leave-active {
    transition-timing-function: ease-in;
  }
}

.ob-combo-box-options {
  margin: 0;
  padding: 0;
}

.ob-combo-box-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  text-align: center;
  color: #907ff5;
  font-size: 24px;

  &__icon {
    display: flex;
    animation: spinner 1s linear infinite;
  }
}

.ob-combo-box-message {
  padding: 12px;
  font-size: 14px;
  line-height: 24px;
  color: colors.$primary-80;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
