<script lang="ts" setup>
interface Props {
  color: string;
  label: string;
}

const props = defineProps<Props>();
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.icon" :style="{ backgroundColor: props.color }" />
    <div :class="$style.label">{{ props.label }}</div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';

.root {
  display: inline-flex;
  color: colors.$primary;
}
.icon {
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: colors.$surface-40;
  margin: 4px 8px 0 0;
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
</style>
