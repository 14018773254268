<script lang="ts" setup>
import { computed, useSlots, useCssModule, h, mergeProps } from 'vue';
import { validateAsChild } from '../../utils';
import type { StatusBasedVariant } from '../../shared/types';

interface Props {
  asChild?: boolean;
  clickable?: boolean;
  variant: StatusBasedVariant;
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  clickable: false,
  variant: 'default',
});

const slots = useSlots();
const style = useCssModule();

const attrs = computed(() => ({
  class: [
    style.root,
    {
      [style.clickable]: props.clickable,
      [style.variantInfo]: props.variant === 'info',
      [style.variantWarning]: props.variant === 'warning',
      [style.variantNegative]: props.variant === 'negative',
      [style.variantPositive]: props.variant === 'positive',
    },
  ],
}));

defineRender(() => {
  const children = slots.default?.();

  if (props.asChild) {
    if (!children || !validateAsChild(children)) {
      // TODO: add warning?
      return null;
    }

    return h(children[0], mergeProps(attrs.value, children[0].props ?? {}));
  }

  const leadingVisual = slots.leadingVisual?.();

  return h('span', attrs.value, [
    leadingVisual ? h('span', { class: style.leadingVisual }, leadingVisual) : null,
    children,
  ]);
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  cursor: default;
  display: inline-flex;
  vertical-align: top;
  color: #35416d;
  text-align: center;
  font-family: typography.$font-family-primary;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 500;
  border-radius: 11px;
  padding: 0 8px;
  height: 22px;
  align-items: center;
  white-space: nowrap;
  background: #f0f1f4; // TODO: design token
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.leadingVisual {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 8px;
  height: 18px;
  min-width: 16px;
  max-width: 18px;
  margin-right: 8px;
  color: #9aa0b6;
}

.variantInfo {
  background: #e6effb; // TODO: design token

  .leadingVisual {
    color: #085cd9;
  }
}

.variantWarning {
  background: #fff3ed; // TODO: design token

  .leadingVisual {
    color: #ff824d;
  }
}

.variantNegative {
  background: #fae1e2; // TODO: design token

  .leadingVisual {
    color: #e42828;
  }
}

.variantPositive {
  background: #e9f8f4; // TODO: design token

  .leadingVisual {
    color: #25b592;
  }
}

// TODO: now clickable only supports info variant
.clickable {
  cursor: pointer;
  color: #085cd9;

  &:hover {
    background: #b5cef4;
  }

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}
</style>
