<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { chunk, isObject } from 'lodash-es';
import { useNow } from '@vueuse/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue';
import {
  addMonths,
  subMonths,
  startOfDay,
  eachDayOfInterval,
  startOfMonth,
  startOfWeek,
  getDate,
  isSameDay,
  formatISO,
  isDate,
  isAfter,
  isBefore,
  isWithinInterval,
  getDay,
  getMonth,
  setMonth,
  getYear,
  setYear,
  isValid,
  addDays,
  parseISO,
} from 'date-fns';
import { ObScrollableContainer } from '../scrollable-container';
import { ObSpace } from '../space';
import { ObButton } from '../button';
import { ObFlexGrid, ObFlexGridItem } from '../flex-grid';
import { ObActionMenu, ObActionList, ObActionListItem } from '../../lab/components';

// TODO: style for selected date outside view
// TODO: hover/focus for nav buttons
// TODO: fix width to fit month name: align select styles + use short month names?
// TODO: does it make sense to support Date and timestamp?
// TODO: [idea] disabledFuture/disablePast
// TODO: disabled dates vs. range selection
// TODO: i18n - months and week days names from config and from props
// TODO: maxDate, minDate - cannot select view, cannot select date
// TODO: a11y - https://primevue.org/calendar/#accessibility

type ModelValue = string | string[] | { start: string | null; end: string | null } | null;
type InternalModelValue = Date | Date[] | { start: Date | null; end: Date | null } | null;

type SelectionMode = 'single' | 'multiple' | 'range';

interface Props {
  currentDate?: string;
  shouldDisableDate?: (date: string) => boolean;
  modelValue?: ModelValue;
  minDate?: string | null;
  maxDate?: string | null;
  selectionMode?: SelectionMode;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

const props = withDefaults(defineProps<Props>(), {
  currentDate: undefined,
  shouldDisableDate: undefined,
  modelValue: null,
  minDate: undefined,
  maxDate: undefined,
  selectionMode: 'single',
  weekStartsOn: 1,
});

const emit = defineEmits<{
  'update:modelValue': [value: ModelValue];
  'update:currentDate': [value: string];
}>();

interface Day {
  date: Date;
  disabled: boolean;
  label: string;
  inHighlightedRange: boolean;
  highlightedRangeStart: boolean;
  highlightedRangeEnd: boolean;
  outsideView: boolean;
  selected: boolean;
  today: boolean;
  weekDay: number;
}

const now = useNow({
  controls: false,
  interval: 1000 * 60,
});
const today = computed(() => startOfDay(now.value));

function parseDate(value: string | null): Date | null {
  if (value === null) {
    return null;
  }

  const date = parseISO(value);

  return isValid(date) ? date : null;
}

function formatDate(date: Date | null): string | null {
  if (!date) {
    return null;
  }

  return formatISO(date, { representation: 'date' });
}

const minDate = computed(() =>
  props.minDate && isValid(new Date(props.minDate)) ? new Date(props.minDate) : null,
);
const maxDate = computed(() =>
  props.maxDate && isValid(new Date(props.maxDate)) ? new Date(props.maxDate) : null,
);

// Current date

const currentDate = ref<Date>(startOfMonth(today.value));

function emitCurrentDate() {
  emit('update:currentDate', formatDate(currentDate.value) as string); // currentDate cannot be null
}

const currentMonth = computed<number>({
  get() {
    return getMonth(currentDate.value);
  },
  set(value) {
    currentDate.value = startOfMonth(setMonth(currentDate.value, value));
    emitCurrentDate();
  },
});

const currentMonthName = computed(() => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][currentMonth.value];
});

const months = computed(() => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].map((item, index) => ({ label: item, value: index }));
});

const currentYear = computed<number>({
  get() {
    return getYear(currentDate.value);
  },
  set(value) {
    currentDate.value = startOfMonth(setYear(currentDate.value, value));
    emitCurrentDate();
  },
});

const years = computed(() => {
  const start = getYear(currentDate.value) - 10;

  return Array.from({ length: 20 }, (_, i) => start + i);
});

function goForward() {
  currentDate.value = addMonths(currentDate.value, 1);
  emitCurrentDate();
}

function goBack() {
  currentDate.value = subMonths(currentDate.value, 1);
  emitCurrentDate();
}

watch(
  () => props.currentDate,
  (value, oldValue) => {
    if (value === oldValue || !value) {
      return;
    }
    currentDate.value = parseDate(value) || startOfMonth(today.value);
  },
  {
    immediate: true,
  },
);

// Model value

const modelValue = ref<InternalModelValue>(null);

const selectedRange = computed(() => {
  if (isObject(modelValue.value) && 'start' in modelValue.value && 'end' in modelValue.value) {
    return modelValue.value;
  }

  return { start: null, end: null };
});

function emitModelValue() {
  let value: ModelValue = null;

  if (props.selectionMode === 'range') {
    value = {
      start: formatDate(selectedRange.value.start),
      end: formatDate(selectedRange.value.end),
    };
  } else if (props.selectionMode === 'multiple') {
    value = Array.isArray(modelValue.value) ? (modelValue.value.map(formatDate) as string[]) : []; // TODO: apply filter to drop null?
  } else {
    value = formatDate(modelValue.value as Date);
  }

  emit('update:modelValue', value);
}

function handleDayClick(day: Day) {
  if (day.disabled) {
    return;
  }

  if (day.outsideView) {
    currentMonth.value = getMonth(day.date);
  }

  switch (props.selectionMode) {
    case 'multiple': {
      if (Array.isArray(modelValue.value) && day.selected) {
        modelValue.value = modelValue.value.flatMap((item) =>
          item && !isSameDay(item, day.date) ? [item] : [],
        );
      } else {
        if (!Array.isArray(modelValue.value)) {
          modelValue.value = [];
        }

        modelValue.value.push(day.date);
      }
      break;
    }
    case 'range': {
      const { start, end } = selectedRange.value;

      if ((start && end) || (!start && !end)) {
        modelValue.value = { start: day.date, end: null };
      } else {
        const selected = start || end;

        modelValue.value =
          selected && isAfter(day.date, selected)
            ? { start: selected, end: day.date }
            : { start: day.date, end: selected };
      }
      break;
    }
    case 'single':
    default: {
      modelValue.value = day.date;
      break;
    }
  }

  emitModelValue();
}

watch(
  () => props.modelValue,
  (value) => {
    if (props.selectionMode === 'single') {
      modelValue.value =
        typeof value === 'string' || typeof value === 'number' ? parseDate(value) : null;

      if (modelValue.value) {
        currentDate.value = startOfMonth(modelValue.value);
      }

      return;
    }

    if (props.selectionMode === 'multiple') {
      modelValue.value = Array.isArray(value)
        ? value.reduce<Date[]>((acc, item) => {
            const date = parseDate(item);

            if (date !== null) {
              acc.push(date);
            }
            return acc;
          }, [])
        : [];
      return;
    }

    if (props.selectionMode === 'range') {
      modelValue.value =
        isObject(value) && 'start' in value && 'end' in value
          ? { start: parseDate(value.start), end: parseDate(value.end) }
          : { start: null, end: null };

      if (modelValue.value.start) {
        currentDate.value = startOfMonth(modelValue.value.start);
      }
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

// Reset model value on selection mode change
watch(
  () => props.selectionMode,
  (value, oldValue) => {
    if (value === oldValue) {
      return;
    }

    if (value === 'single') {
      modelValue.value = null;
      return;
    }

    if (value === 'multiple') {
      modelValue.value = [];
      return;
    }

    if (value === 'range') {
      modelValue.value = { start: null, end: null };
      return;
    }
  },
);

// Hover

const hoveredDate = ref<Date | null>(null);

function handleDayHover(day: Day) {
  if (day.disabled) {
    return;
  }
  hoveredDate.value = day.date;
}

const hoveredRange = computed(() => {
  if (props.selectionMode !== 'range' || !hoveredDate.value) {
    return { start: null, end: null };
  }

  const { start, end } = selectedRange.value;

  if (start && !end) {
    if (isBefore(hoveredDate.value, start)) {
      return { start: hoveredDate.value, end: start };
    }

    if (isAfter(hoveredDate.value, start)) {
      return { start, end: hoveredDate.value };
    }

    return { start, end: hoveredDate.value };
  }

  return { start: null, end: null };
});

// Render

const days = computed<Day[]>(() => {
  const start = startOfWeek(startOfMonth(currentDate.value), { weekStartsOn: props.weekStartsOn });
  const end = addDays(start, 41);

  return eachDayOfInterval({
    start,
    end,
  }).map((date) => {
    let selected = false;
    let inHighlightedRange = false;
    let highlightedRangeStart = false;
    let highlightedRangeEnd = false;

    switch (props.selectionMode) {
      case 'single': {
        selected = isDate(modelValue.value) && isSameDay(date, modelValue.value as Date);
        break;
      }
      case 'multiple': {
        selected =
          Array.isArray(modelValue.value) &&
          modelValue.value.some((item) => item && isSameDay(item, date));
        break;
      }
      case 'range': {
        const { start: selectedStart, end: selectedEnd } = selectedRange.value;
        const { start: hoveredStart, end: hoveredEnd } = hoveredRange.value;

        selected =
          (!!selectedStart && isSameDay(date, selectedStart)) ||
          (!!selectedEnd && isSameDay(date, selectedEnd));

        inHighlightedRange =
          selected ||
          (!!selectedStart &&
            !!selectedEnd &&
            isWithinInterval(date, { start: selectedStart, end: selectedEnd })) ||
          (!!hoveredStart &&
            !!hoveredEnd &&
            isWithinInterval(date, { start: hoveredStart, end: hoveredEnd }));

        highlightedRangeStart =
          (!!selectedStart && !!selectedEnd && isSameDay(date, selectedStart)) ||
          (!!hoveredStart && isSameDay(date, hoveredStart));

        highlightedRangeEnd =
          (!!selectedStart && !!selectedEnd && isSameDay(date, selectedEnd)) ||
          (!!hoveredEnd && isSameDay(date, hoveredEnd));

        break;
      }
      default:
        break;
    }

    let disabled = false;

    if (minDate.value && isBefore(date, minDate.value)) {
      disabled = true;
    } else if (maxDate.value && isAfter(date, maxDate.value)) {
      disabled = true;
    } else if (typeof props.shouldDisableDate === 'function') {
      disabled = props.shouldDisableDate(formatISO(date, { representation: 'date' }));
    }

    return {
      date,
      weekDay: getDay(date),
      label: getDate(date).toString(),
      today: isSameDay(date, today.value),
      selected,
      disabled,
      outsideView: getMonth(date) !== currentMonth.value,
      inHighlightedRange,
      highlightedRangeStart,
      highlightedRangeEnd,
    };
  });
});

const rows = computed(() => chunk(days.value, 7));

const weekDaysDefault = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const weekDays = computed<string[]>(() => {
  // TODO: make sure props.weekStartsOn length is 7
  return [
    ...weekDaysDefault.slice(props.weekStartsOn),
    ...weekDaysDefault.slice(0, props.weekStartsOn),
  ];
});
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.header">
      <ObFlexGrid spacing-x="4" align-items="center" justify-content="between">
        <ObFlexGridItem size="auto">
          <button type="button" :class="$style.arrow" @click="goBack()">
            <IconChevronLeft />
          </button>
        </ObFlexGridItem>
        <ObFlexGridItem size="auto">
          <ObSpace spacing="4">
            <ObActionMenu max-height="sm">
              <template #host>
                <ObButton size="m" variant="tertiary">{{ currentMonthName }}</ObButton>
              </template>
              <!-- TODO: find better solution than inline styles -->
              <div
                style="display: flex; flex-direction: column; height: inherit; max-height: inherit"
              >
                <ObScrollableContainer light>
                  <ObActionList selection-mode="single">
                    <ObActionListItem
                      v-for="month in months"
                      :key="month.label"
                      :selected="currentMonth === month.value"
                      @select="currentMonth = month.value"
                    >
                      {{ month.label }}
                    </ObActionListItem>
                  </ObActionList>
                </ObScrollableContainer>
              </div>
            </ObActionMenu>
            <ObActionMenu max-height="sm">
              <template #host>
                <ObButton size="m" variant="tertiary">{{ currentYear }}</ObButton>
              </template>
              <div
                style="display: flex; flex-direction: column; height: inherit; max-height: inherit"
              >
                <ObScrollableContainer light>
                  <ObActionList selection-mode="single">
                    <ObActionListItem
                      v-for="year in years"
                      :key="year"
                      :selected="currentYear === year"
                      @select="currentYear = year"
                    >
                      {{ year }}
                    </ObActionListItem>
                  </ObActionList>
                </ObScrollableContainer>
              </div>
            </ObActionMenu>
          </ObSpace>
        </ObFlexGridItem>
        <ObFlexGridItem size="auto">
          <button type="button" :class="$style.arrow" @click="goForward()">
            <IconChevronRight />
          </button>
        </ObFlexGridItem>
      </ObFlexGrid>
    </div>
    <div :class="$style.body">
      <div :class="$style.weekDays">
        <div v-for="weekDay in weekDays" :key="weekDay" :class="$style.cell">
          <div :class="$style.weekDay">
            {{ weekDay }}
          </div>
        </div>
      </div>
      <div @mouseleave="hoveredDate = null">
        <div v-for="(row, i) in rows" :key="i" :class="$style.row">
          <div
            v-for="(day, j) in row"
            :key="j"
            :class="[
              $style.day,
              {
                [$style.dayToday]: day.today,
                [$style.daySelected]: day.selected,
                [$style.dayDisabled]: day.disabled,
                [$style.dayOutsideView]: day.outsideView,
                [$style.dayOutsideView]: day.outsideView,
                [$style.dayInHighlightedRange]: day.inHighlightedRange,
                [$style.dayHighlightedRangeStart]: day.highlightedRangeStart,
                [$style.dayHighlightedRangeEnd]: day.highlightedRangeEnd,
              },
            ]"
            role="button"
            aria-disabled="false"
            tabindex="0"
            @mouseenter="handleDayHover(day)"
            @click="handleDayClick(day)"
          >
            <div :class="$style.dayLabel">{{ day.label }}</div>
            <div v-if="day.today" :class="$style.todayMark" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';
@use '../../styles/shared';

.root {
  width: 7 * 40px;
  height: 348px;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: #fff;
  color: colors.$primary;
}

.header {
  margin-bottom: 12px;
  overflow: hidden;
}

.body {
  height: 6 * 40px;
  margin: 0 auto;
}

.weekDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 4px;
}

.weekDay {
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 500;
}

.row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  & + & {
    margin-top: 4px;
  }
}

.day {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: colors.$primary;
    border-radius: 50%;
  }

  &:before {
    z-index: 5;
  }

  &::after {
    z-index: 10;
  }

  &:hover::after {
    background: #ded9fc; // TODO: token
  }
}

.dayLabel {
  position: relative;
  z-index: 15;
}

.daySelected {
  color: colors.$white;

  &::after {
    background: #907ff5; // TODO: token
  }

  &:hover::after {
    background: #7a68e3; // TODO: token
  }
}

.dayInHighlightedRange:not(.daySelected) {
  &::after {
    background: #ded9fc; // TODO: token
    border-radius: 0;
  }

  &:first-child,
  &.dayHighlightedRangeStart {
    &::after {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }

  &:last-child,
  &.dayHighlightedRangeEnd {
    &::after {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}

.daySelected.dayHighlightedRangeStart:not(.dayHighlightedRangeEnd):not(:last-child) {
  &::before {
    background: #ded9fc; // TODO: token
    border-radius: 50% 0 0 50%;
  }
}

.daySelected.dayHighlightedRangeEnd:not(.dayHighlightedRangeStart):not(:first-child) {
  &::before {
    background: #ded9fc; // TODO: token
    border-radius: 0 50% 50% 0;
  }
}

.dayOutsideView {
  color: #9aa0b6;
}

.dayOutsideView.daySelected {
  color: #fff;

  &::before {
    background: #ded9fc;
  }
}

.dayDisabled {
  color: #9aa0b6;
}

.todayMark {
  position: absolute;
  width: 4px;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  border-radius: 100%;
  background: #907ff5;
  z-index: 20;
}

.arrow {
  @include shared.reset-button();
  width: 24px;
  height: 24px;
  color: #9aa0b6;
  vertical-align: top;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }

  &:disabled,
  &._disabled {
    cursor: not-allowed;
  }
}
</style>
