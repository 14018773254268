export const heightMap = {
  xs: '192px',
  sm: '256px',
  md: '320px',
  lg: '432px',
  xl: '600px',
  auto: 'auto',
};

export const widthMap = {
  sm: '256px',
  md: '320px',
  lg: '480px',
  xl: '640px',
  xxl: '960px',
  auto: 'auto',
};
