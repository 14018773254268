<script lang="ts" setup>
import { IconChevronDown } from '@tabler/icons-vue';
import { hasSlotContent } from '../../utils';

interface Props {
  ariaControls?: string;
  ariaLabel?: string;
  disabled?: boolean;
  expanded?: boolean;
  chevronPosition?: 'start' | 'end';
}

const props = withDefaults(defineProps<Props>(), {
  ariaControls: undefined,
  ariaLabel: 'Toggle disclosure',
  disabled: false,
  expanded: false,
  chevronPosition: 'end',
});
</script>

<template>
  <button
    type="button"
    role="button"
    :class="[$style.root, { [$style.expanded]: props.expanded }]"
    :disabled="disabled"
    :aria-controls="ariaControls"
    :aria-expanded="expanded"
    :aria-label="hasSlotContent($slots.default) ? undefined : ariaLabel"
  >
    <span v-if="props.chevronPosition === 'start'" :class="$style.icon">
      <IconChevronDown aria-hidden="true" />
    </span>
    <span v-if="hasSlotContent($slots.default)" :class="$style.text"><slot /></span>
    <span v-if="props.chevronPosition === 'end'" :class="$style.icon">
      <IconChevronDown aria-hidden="true" />
    </span>
  </button>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: shared.$border-radius-s;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: colors.$primary;
  vertical-align: top;

  &:focus-visible .icon {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }

  &:disabled {
    cursor: not-allowed;
    color: colors.$surface-40;
  }

  &:hover:enabled .icon {
    background: #f2f0fe; // TODO: use design token
  }

  &:active:enabled .icon {
    background-color: #cbc3fa; // TODO: use design token
  }
}

.icon {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  transition: transform 0.2s ease-in-out;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.icon + .text,
.text + .icon {
  margin-left: 8px;
}

.expanded .icon {
  transform: rotate(180deg);
}
</style>
