<script lang="ts" setup>
import { computed } from 'vue';

// TODO: support height as base for ratio

interface Props {
  ratio?: number;
}

const props = withDefaults(defineProps<Props>(), {
  ratio: 1,
});

const padding = computed(() => (props.ratio > 0 ? `${100 * props.ratio}%` : '100%'));
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.root {
  position: relative;
}

.inner {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: v-bind(padding);
  user-select: none;
  width: 100%;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
