<script lang="ts" setup>
interface Props {
  disabled?: boolean;
  label?: string;
  selected?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  label: '',
  selected: false,
});
</script>

<template>
  <li
    class="ob-combo-box-option"
    :class="{ _selected: props.selected }"
    :aria-selected="props.selected"
    :aria-label="props.label"
    role="option"
    tabindex="-1"
  >
    <div class="ob-combo-box-option__label">
      <slot>{{ props.label }}</slot>
    </div>
  </li>
</template>

<style lang="scss">
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.ob-combo-box-option {
  position: relative;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 12px;
  background: #fff;
  outline: none;
  font-family: typography.$font-family-primary;
  cursor: pointer;

  &__label {
    font-size: 14px;
    line-height: 20px;
    color: colors.$primary;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }

  &._selected {
    background: #f8f7fe; // TODO: use token

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 3px;
      left: 0;
      top: 0;
      background: #907ff5; // TODO: use token
    }
  }

  &:hover,
  &:focus-visible {
    background: #f2f0fe; // TODO: use token
  }
}
</style>
