/**
 * Convert number to string with replacing exponent part on decimals
 */

export function numberToStringWithoutExp(value: number): string {
  const valueAsString = String(value);
  const [numberPart, expPart] = valueAsString.split('e-');

  let valueWithoutExp = valueAsString;

  if (expPart) {
    const [, decimals] = numberPart.split('.');
    const decimalDigits = Number(expPart) + (decimals?.length || 0);

    valueWithoutExp = value.toFixed(decimalDigits);
  }

  return valueWithoutExp;
}
